@use '../setting' as *;
@use '../mixin/mixin' as *;
body#list {
  section.dl {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1200px;
    h2 {
      @include h2;
      & {
        margin-bottom: 110px;
        padding-top: 150px;
        font-size: 30px;
      }
      span {
        margin-bottom: 10px;
        font-size: 14px;
      }
      div{
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
  .dl-content {
    margin-bottom: 40px;
    h3 {
      margin-bottom: 60px;
      font-size: 22px;
      font-weight: bold;
      position: relative;
      &::after {
        position: absolute;
        content: '';
        width: 27px;
        height: 1px;
        background: $color-blue;
        bottom: -15px;
        left: 0;
      }
    }
    ul.dl-content-li {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      li {
        margin: 0 30px 60px 0;
        width: calc((100% - 60px) / 3);
        &:nth-child(3n) {
          margin-right: 0;
        }
        .dl-content-li-img {
          width: 100%;
          img {
            width: 100%;
          }
        }
        h4 {
          margin: 25px 0 22px;
          line-height: 1.5;
          font-size: 18px;
          font-weight: bold;
          color: $color-blue;
        }
        p {
          margin-bottom: 30px;
        }
        a {
          @include btn-white;
          padding-left: 34px;
          width: 240px;
          height: 60px;
          line-height: 60px;
          font-size: 18px;
          position: relative;
          background: #f0f0f0;
          border-radius: 0;
          text-align: left;
          &::after {
            position: absolute;
            content: '';
            width: 21px;
            height: 19px;
            background-image: url('/assets/images/common/ic_dl_b.png');
            background-repeat: no-repeat;
            background-size: 21px 19px;
            top: 20px;
            right: 25px;
          }
          &:hover {
            background: $color-blue;
            color: #fff;
            opacity: 1;
            &::after {
              background-image: url('/assets/images/common/ic_dl_w.png');
            }
          }
        }
      }
    }
  }
  // SP list
  @include media(sp) {
    section.dl {
      margin: 0 auto;
      padding: 0 4vw;
      max-width: 100%;
      box-sizing: border-box;
      h2 {
        margin-bottom: 12vw;
        padding-top: 23.2vw;
        font-size: 4.26vw;
        span {
          margin-bottom: 10px;
          font-size: 2.4vw;
        }
      }
    }
    .dl-content {
      margin-bottom: 18vw;
      h3 {
        margin-bottom: 8.8vw;
        font-size: 3.73vw;
        text-align: center;
        &::after {
          position: absolute;
          content: '';
          width: 3.6vw;
          bottom: -12px;
          left: calc(50% - 1.8vw);
        }
      }
      ul.dl-content-li {
        width: 100%;
        display: block;
        li {
          margin: 0 0 10.66vw;
          width: 100%;
          &:last-child {
            margin-bottom: 0;
          }
          h4 {
            margin: 4.66vw 0 4.3vw;
            font-size: 3.46vw;
            text-align: center;
          }
          p {
            margin: 0 auto 5vw;
            width: 86.67vw;
          }
          a {
            @include btn-blue;
            margin: 0 auto;
            padding-left: 8vw;
            width: 44.66vw;
            height: 10.93vw;
            line-height: 10.93vw;
            font-size: 3.2vw;
            color: #fff;
            text-align: left;
            &::after {
              position: absolute;
              content: '';
              width: 3.77vw;
              height: 3.46vw;
              background-image: url('/assets/images/common/ic_dl_w.png');
              background-repeat: no-repeat;
              background-size: 3.77vw 3.46vw;
              top: 3.6vw;
              right: 3.6vw;
            }
            &:hover {
              background: #f0f0f0;
              color: $color-black;
              &::after {
                background-image: url('/assets/images/common/ic_dl_b.png');
              }
            }
          }
        }
      }
    }
  }
}
