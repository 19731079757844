@use '../setting' as *;
@use '../mixin/mixin' as *;
body.voice {
  .mv {
    width: 100%;
    img {
      width: 100%;
    }
  }
  section {
    h2 {
      margin-bottom: 30px;
      line-height: 1.8;
      font-size: 30px;
      font-weight: bold;
      color: $color-blue;
      span {
        font-size: 115%;
        font-family: $font-en;
      }
    }
    h3 {
      margin-bottom: 10px;
      line-height: 1.9;
      font-size: 22px;
      font-weight: bold;
    }
    p {
      margin-bottom: 10px;
      &.bold {
        font-weight: bold;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .sec-inner {
      margin: 0 auto;
      padding: 0 10px;
      max-width: 1200px;
    }
    .sec-wrap {
      margin-bottom: 60px;
      width: 100%;
      &:last-child {
        margin-bottom: 100px;
      }
    }
  }
  section.sec1 {
    background: url('/assets/images/voice/bg1_pc.png') top 0 right 0 / 827px 776px no-repeat;
    .sec1-logo {
      margin: 0 auto 150px;
      padding-top: 60px;
      width: 100%;
      img {
        width: 100%;
      }
    }
    h2 {
      margin-bottom: 15px;
    }
    p.sec1-company-name {
      margin-bottom: 50px;
      font-weight: bold;
    }
    .sec1-description {
      margin-bottom: 60px;
    }
    .sec1-img {
      margin: 0 auto 200px;
      max-width: 1000px;
      img {
        width: 100%;
      }
      p {
        margin-top: 20px;
        font-size: 14px;
        line-height: 1.9;
        span {
          display: block;
        }
      }
    }
  }
  .wide-ph {
    margin-bottom: 160px;
    width: 100%;
    // background: #003981;
    background: #103377;
    .wide-ph-content {
      margin: 0 auto;
      max-width: 1200px;
      // background: #003981;
      background: #103377;
      img {
        width: 100%;
        vertical-align: bottom;
      }
    }
  }
  section.sec2 {
    background: url('/assets/images/voice/bg2_pc.png') no-repeat top 364px right 0 / 874px 776px;
    .sec-ph-l {
      margin: 0 auto 0 0;
      float: left;
      width: calc(100% - 560px - 70px);
      height: 700px;
      img {
        width: 100%;
      }
    }
    .sec-ph-l-txt {
      float: right;
      max-width: 560px;
    }
  }
  section.sec3 {
    padding-top: 96px;
    background: url('/assets/images/voice/bg3_pc.png') no-repeat 0 0 / 840px 1183px;
    .sec-ph-r {
      margin: 0 0 0 auto;
      float: right;
      width: calc(100% - 560px - 70px);
      height: 670px;
      img {
        width: 100%;
      }
    }
    .sec-ph-r-txt {
      float: left;
      max-width: 560px;
    }
    p.sec2-notice {
      margin-bottom: 140px;
      font-size: 14px;
      text-align: center;
    }

    .sec-wrap-img {
      display: block;
      width: 100%;
      max-width: 800px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
  section.recommend {
    padding: 120px 0 150px;
    background: $color-gray2;
    
    h2 {
      margin-bottom: 60px;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      color: $color-black;
    }
    ul {
      width: 100%;
      display: flex;
      li {
        margin-right: 30px;
        width: calc((100% - 60px) / 3);
        &:nth-child(3) {
          margin-right: 0;
        }
        a {
          @include fade;
          display: flex;
          flex-wrap: wrap;
        }
        .recommend-ph {
          width: 100%;
          order: 1;
          img {
            width: 100%;
          }
        }
        p {
          margin: 10px 0;
          order: 2;
        }
        .recommend-logo {
          order: 3;
        }
      }
    }
  }
  // SP voice
  @include media(sp) {
    .mv {
      width: 100%;
      img {
        width: 100%;
      }
    }
    section {
      h2 {
        margin-bottom: 2.66vw;
        font-size: 4.66vw;
      }
      h3 {
        margin-bottom: 5vw;
        font-size: 3.46vw;
      }
      p {
        margin-bottom: 5vw;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .sec-inner {
        margin: 0 auto;
        padding: 0 6.6vw;
        max-width: 100%;
        box-sizing: border-box;
      }
      .sec-wrap {
        margin-bottom: 13.3vw;
        &:last-child {
          margin-bottom: 13.3vw;
        }
      }

      .sec-wrap-img{
          display: block;
          width: 100%;
          max-width: 800px;
          overflow: hidden; /* 必要に応じてコンテンツのはみ出しを防ぐ */

          img{
            width: 100%;
            height: auto; /* アスペクト比を維持 */
            max-width: 100%; /* 画像が画面幅を超えない */
          }
      }
    }
    section.sec1 {
      background: url('/assets/images/voice/bg1_sp.png') top 0 right 0 / 75.4vw 103.46vw no-repeat;
      .sec1-logo {
        margin: 0 auto 9.33vw;
        padding-top: 8vw;
      }
      h2 {
        margin-bottom: 5vw;
      }
      p.sec1-company-name {
        margin-bottom: 5vw;
      }
      .sec1-description {
        margin-bottom: 10.6vw;
      }
      .sec1-img {
        margin: 0 auto 14.66vw;
        max-width: 100%;
        p {
          margin-top: 5.33vw;
          font-size: 3.2vw;
          span {
            margin-top: 3vw;
            display: block;
          }
        }
      }
    }
    .wide-ph {
      margin-bottom: 13.3vw;
      background: transparent;
      .wide-ph-content {
        max-width: 100%;
        background: transparent;
      }
    }
    section.sec2 {
      background: url('/assets/images/voice/bg2_sp.png') no-repeat top 93.33vw left 0 / 73.86vw 166.66vw;
      .sec-ph-l-txt {
        float: none;
        width: 100%;
        position: relative;
      }
      .sec2-ph {
        margin: 0 auto 13.33vw;
        width: 73.33vw;
        height: 91.73vw;
        position: relative;
        img {
          width: 100%;
        }
      }
    }
    section.sec3 {
      padding-top: 0;
      background: url('/assets/images/voice/bg3_sp.png') no-repeat top 0 right 0 / 69.73vw 166.66vw;
      .sec-ph-r {
        margin: 0 0 16vw auto;
        float: none;
        width: 80vw;
        height: 89.3vw;
        right: -6.6vw;
        position: relative;
      }
      .sec-ph-r-txt {
        float: none;
        width: 100%;
      }
      p.sec2-notice {
        margin-bottom: 13.33vw;
        font-size: 3.2vw;
      }
    }
    section.recommend {
      padding: 18vw 0;
      h2 {
        margin-bottom: 9.33vw;
        font-size: 3.73vw;
      }
      ul {
        display: block;
        li {
          margin: 0 0 8vw;
          width: 100%;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            width: 100%;
            position: relative;
            display: block;
          }
          .recommend-ph {
            margin: 0 auto 0 0;
            width: 43.3vw;
            height: 28.53vw;
            img {
              width: 100%;
            }
          }
          p {
            margin: 0;
            width: calc(100% - 43.3vw - 4vw);
            position: absolute;
            top: 0;
            right: 0;
          }
          .recommend-logo {
            margin-top: 1.3vw;
            margin-right: calc(100% - 43.3vw);
            width: 43.3vw;
            img {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

