@use 'sass:meta';
@use 'sass:map';
@use '../setting' as *;

$breakpoints: (
  'sp': 'screen and (max-width: 999px)',
  'pc': 'screen and (min-width: 1000px)',
  'w1230': 'screen and (min-width: 1000px) and (max-width: 1230px)',
  'w1270': 'screen and (min-width: 1000px) and (max-width: 1270px)',
  'w1300': 'screen and (min-width: 1000px) and (max-width: 1300px)',
  'w1400': 'screen and (min-width: 1000px) and (max-width: 1400px)',
);

@mixin media($breakpoint) {
  @if map.has-key($breakpoints, $breakpoint) {
    @media #{map.get($breakpoints, $breakpoint)} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin bold {
  color: $color-black2;
  font-weight: bold;
}

@mixin fade {
  transition: .3s all;
  &:hover {
    opacity: .7;
  }
}

@mixin h2 {
  margin-bottom: 60px;
  text-align: center;
  color: $color-blue;
  font-size: 36px;
  font-weight: bold;
  span {
    margin-bottom: 15px;
    display: block;
    color: $color-gray1;
    font-family: $font-en;
    font-size: 20px;
  }
  @include media(sp) {
    margin-bottom: 8.5vw;
    font-size: 5.33vw;
    span {
      margin-bottom: 1.3vw;
      font-size: 2.66vw;
    }
  }
}

@mixin bg-border {
  background: url('/assets/images/common/bg_border_pc.png') repeat;
  background-size: 1px 4px;
  @include media(sp) {
    background: url('/assets/images/common/bg_border_sp.png') repeat;
    background-size: 1px 4px;
  }
}

@mixin btn {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 99px;
  box-sizing: border-box;
  font-weight: bold;
}

@mixin btn-blue {
  @include btn;
  background: $color-blue;
  color: #fff;
  &:hover {
    opacity: .85;
  }
}

@mixin btn-white {
  @include btn;
  background: #fff;
  color: $color-blue;
  &:hover {
    opacity: .8;
  }
}

@mixin btn-contact1 {
  @include btn-blue;
  text-align: right;
  position: relative;
  &::before {
    width: 20px;
    height: 18px;
    content: '';
    background: url('/assets/images/common/ic_contact.png');
    background-size: 20px 18px;
    position: absolute;
    top: 13px;
    left: 20px;
  }
}

@mixin btn-contact2 {
  @include btn-contact1;
  padding-right: 80px;
  font-size: 22px;
  &::before {
    width: 23px;
    height: 21px;
    background-size: 23px 21px;
    top: 25px;
    left: 85px;
  }
  @include media(sp) {
    padding-right: 29.3vw;
    font-size: 3.73vw;
    &::before {
      width: 3.86vw;
      height: 3.6vw;
      background: url('/assets/images/common/ic_contact.png');
      background-size: 3.86vw 3.6vw;
      top: 4.5vw;
      left: 26.6vw;
    }
  }
}

@mixin slide-content {
  padding: 5px 5px 5px 15px;
  display: flex;
  width: 100%;
  li {
    width: calc((100% / 3) - 40px);
    display: flex;
    @include fade;
    .slide-li {
      margin: 5px 15px 5px 5px;
      background: #fff;
      box-shadow: 0 0 8px #c5c5c5;
      box-sizing: border-box;
      img {
        width: 100%;
      }
    }
    .slide-txt {
      padding: 30px 35px;
      h3 {
        margin-bottom: 30px;
        line-height: 1.8;
        color: $color-blue;
        font-size: 20px;
        font-weight: bold;
      }
    }
    @include media(pc) {
      .slide-li {
        display: flex;
      }
      a {
        display: flex;
        flex-direction: column;
        width: 100%;
        .slide-ph {
          img {
            width: 100%;
          }
        }
        .slide-txt {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }
      }
    }
  }
  @include media(w1270) {
    button.slick-prev {
      left: 0;
    }
    button.slick-next {
      right: 0;
    }
  }
  @include media(sp) {
    padding: 0;
    display: block;
    li {
      width: 100%;
      .slide-li {
        margin: 0 0 4vw;
        a {
          display: flex;
          flex-wrap: nowrap;
        }
      }
      .slide-ph {
        width: 40vw;
        height: 40vw;
        img {
          width: 100%;
        }
      }
      .slide-txt {
        padding: 5.5vw 5.6vw 5vw;
        width: calc(100% - 40vw);
        box-sizing: border-box;
        h3 {
          margin-bottom: 3.2vw;
          font-size: 3.2vw;
        }
        p {
          font-size: 2.66vw;
        }
      }
    }
  }
}
