//antialiased,subpixel-antialiased,none
$font-smoothing: antialiased;

//auto,optimizeSpeed,optimizeLegibility,geometricPrecision
$text-rendering: auto;

// ------------
// Fonts
// ------------
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500;600');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre');
@import url('https://fonts.googleapis.com/css2?family=Montserrat');

$font-base: '游ゴシック', YuGothic, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;
$font-en: 'Barlow', '游ゴシック', YuGothic, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;
$font-en-con: 'Barlow Condensed', '游ゴシック', YuGothic, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;
$font-mincho: 'Frank Ruhl Libre', '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝', serif;

// ------------
// Colors
// ------------

$color-blue: #003981;
$color-black: #000000;
$color-black2: #1e1e1e;
$color-gray3: #dcdcdc;
$color-gray2: #f4f4f4;
$color-gray1: #787878;
$color-gray: #969696;

// ------------
// Base
// ------------
$base-font-size: 62.5%;
$base-font-color: $color-black;
$base-background-color: #fff;
$inner-width: 1000px;
$sp-width: 768px;
