@use '../setting' as *;
@use '../mixin/mixin' as *;

body#seminar{
  section.seminar {
    padding: 95px 0 115px;
    @include bg-border;
    h2 {
      @include h2;
    }
    .seminar-inner {
      margin: 0 auto;
      max-width: 1200px;
    }
    .seminar-content {
      @include slide-content;
      li {
        .tag {
          span {
            margin: 0 16px 16px 0;
            padding: 3px 10px;
            line-height: 1;
            font-size: 16px;
            display: inline-block;
            font-weight: bold;
          }
          @media all and (-ms-high-contrast:none) {
            span {
              padding: 5px 10px 0;
            }
          }
          span.online {
            border: 1px $color-blue solid;
            color: $color-blue;
          }
          span.before {
            color: $color-gray1;
            background: #ebebeb;
          }
          span.accepting {
            color: #fff;
            background: $color-blue;
          }
          span.closed {
            border: 1px $color-gray3 solid;
            color: $color-gray;

            // color: #333;
            // background: $color-gray3;
          }
        }
        .tag-top {
          padding: 10px;
          width: 100%;
          box-sizing: border-box;
          span {
            margin-bottom: 0;
          }
        }
        .slide-txt {
          font-weight: bold;
          h3 {
            margin: 10px 0 30px;
            text-decoration: underline;
          }
          span.date {
            margin-top: auto;
            color: $color-gray;
            font-family: $font-en;
            font-size: 14px;
          }
        }
        &.closed {
          h3 {
            color: $color-gray;
          }
          .slide-txt  {
            span.online {
              border: 1px $color-gray3 solid;
              color: $color-gray;
            }
          }
        }
      }
    }

    .seminar-btn {
      width: 350px;
      height: 70px;
      margin: 60px auto 0 auto;
      
      a {
        line-height: 70px;
        font-size: 22px;
        padding-right: 0;
        font-size: 22px;
        @include btn-contact1;
        text-align:center;

        @include media(sp) {
          padding-right: 29.3vw;
          font-size: 3.73vw;
        }

        &::before {
          content: none;
        }
      }
    }
    // SP seminar
    @include media(sp) {
      padding: 17.33vw 0 10.93vw;
      h2 {
        @include h2;
      }
      .seminar-inner {
        margin: 0 auto;
        width: 86.66vw;
        max-width: auto;
      }
      .seminar-content {
        @include slide-content;
        li {
          .slide-li a {
            flex-wrap: wrap !important;
          }
          .tag {
            span {
              margin-right: 1.73vw;
              padding: 0 1.33vw;
              height: 4.8vw;
              line-height: 4.8vw;
              font-size: 2.66vw;
              font-weight: bold;
              text-align: center;
            }
          }
          .slide-ph {
            width: 100% !important;
            height: auto !important;
            img {
              height: 100%;
            }
          }
          .tag-top {
            padding: 1.86vw 2.66vw;
          }
          .tag-btm {
            width: 100%;
          }
          .slide-txt {
            padding: 1.86vw 4vw 3.06vw !important;
            width: 100% !important;
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            h3 {
              margin: 0 !important;
              flex-grow: 1;
              font-size: 3.46vw;
            }
            span.date {
              margin-top: 2.66vw;
              width: 100%;
              font-size: 2.66vw;
              display: block;
            }
          }
          &.closed {
            h3 {
              color: $color-gray;
            }
          }
        }
      }

      .seminar-btn {
        width: 100%;
        height: 12.5vw;
        margin: 6vw auto 0 auto;
        a {
          line-height: 12.5vw;
          padding-right: 0;
        }
      }
    }
  }
}


