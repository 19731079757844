@use '../setting' as *;
@use '../mixin/mixin' as *;
section.contact {
  padding-top: 125px;
  width: 100%;
  height: 275px;
  background: url('/assets/images/home/cv_bg_pc.jpg') no-repeat center;
  background-size: cover;
  text-align: center;
  h2 {
    margin-bottom: 45px;
    color: #fff;
    font-size: 35px;
    font-weight: bold;
  }
  .contact-btn {
    display: flex;
    justify-content: center;
    li {
      width: 350px;
      height: 70px;
      a {
        line-height: 70px;
        font-size: 22px;
      }
      &:first-child {
        a {
          @include btn-contact2;
        }
      }
      &:last-child {
        margin-left: 40px;
        a {
          @include btn-white;
          font-size: 18px;
        }
      }
    }
  }
  @include media(sp) {
    padding: 20vw 6.6vw 0;
    height: 80vw;
    background: url('/assets/images/home/cv_bg_sp.jpg') no-repeat center;
    background-size: cover;
    box-sizing: border-box;
    h2 {
      margin-bottom: 5.3vw;
      font-size: 6.66vw;
    }
    .contact-btn {
      display: block;
      li {
        width: 100%;
        height: 12.5vw;
        a {
          line-height: 12.5vw;
        }
        &:last-child {
          margin: 4vw 0 0;
          a {
            font-size: 3.73vw;
          }
        }
      }
    }
  }
}
