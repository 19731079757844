@use '../setting' as *;
@use '../mixin/mixin' as *;
@charset "UTF-8";

footer {
  position: relative;
  background: #fff;
  .footer-top {
    padding-top: 150px;
    width: 100%;
    height: 560px;
    background: url('/assets/images/common/footer_bg_pc.jpg') no-repeat;
    background-size: cover;
    background-position: right;
    box-sizing: border-box;
    .footer-top-inner {
      margin: 0 auto;
      padding: 0 10px;
      max-width: 1000px;
      font-weight: bold;
      color: #fff;
      span {
        display: block;
      }
      span:first-child {
        margin-bottom: 20px;
        font-size: 45px;
        text-indent: -8px;
      }
      span:nth-child(2) {
        margin-bottom: 10px;
        font-size: 22px;
      }
      a:nth-child(3) {
        margin-bottom: 15px;
        padding-left: 30px;
        font-size: 40px;
        color: #fff;
        font-family: $font-en;
        position: relative;
        display: inline-block;
        &::before {
          width: 25px;
          height: 32px;
          position: absolute;
          content: '';
          background: url('/assets/images/common/ic_tel.png') no-repeat;
          background-size: 25px 32px;
          top: 8px;
          left: 0;
        }
      }
      span:nth-child(4) {
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: normal;
      }
      .footer-top-btn {
        ul {
          display: flex;
        }
        li {
          margin-right: 20px;
          width: 350px;
          height: 70px;
          line-height: 70px;
        }
        li.contact a {
          @include btn-contact2;
        }
        li.list a {
          @include btn-white;
          font-size: 18px;
        }
      }
    }
  }
  .footer-btm {
    margin: 0 auto;
    padding: 70px 10px 30px;
    max-width: 1200px;
    display: flex;
    color: #828282;
    ul {
      display: flex;
      font-size: 14px;
      li {
        margin-right: 30px;
        a {
          color: #828282;
        }
      }
    }
    span {
      margin: 0 0 0 auto;
      display: block;
      font-family: $font-en;
      font-size: 12px;
    }
  }
  // SP footer
  @include media(sp) {
    width: 100%;
    .footer-top {
      padding-top: 54.66vw;
      width: 100%;
      height: 91.2vw;
      background: url('/assets/images/common/footer_bg_sp.jpg') no-repeat;
      background-size: cover;
      .footer-top-inner {
        margin: 0 auto;
        padding: 0;
        max-width: 100%;
        span:first-child {
          margin: 0 0 2.66vw 6.6vw;
          font-size: 8vw;
          text-indent: -1.3vw;
        }
        span:nth-child(2) {
          margin: 0 0 2.66vw 6.6vw;
          font-size: 3.46vw;
        }
        a:nth-child(3) {
          margin: 0 0 2.66vw 6.6vw;
          padding-left: 5vw;
          font-size: 7.33vw;
          &::before {
            width: 4.13vw;
            height: 5.33vw;
            background: url('/assets/images/common/ic_tel.png') no-repeat;
            background-size: 4.13vw 5.33vw;
            top: 1vw;
            left: 0.5vw;
          }
        }
        span:nth-child(4) {
          margin: 0 0 7vw 6.6vw;
          font-size: 3.2vw;
        }
        .footer-top-btn {
          margin-bottom: 12.53vw;
          padding: 6.66vw;
          position: relative;
          top: 0;
          left: 0;
          background: #f4f4f4;
          box-sizing: border-box;
          ul {
            display: block;
          }
          li {
            margin: 0 0 4vw;
            width: 100%;
            height: 12.53vw;
            line-height: 12.53vw;
            &:last-child {
              margin-bottom: 0;
            }
          }
          li.contact a {
            padding-right: 29.33vw;
            &::before {
              left: 26.93vw;
            }
          }
          li.list a {
            border: 2px $color-blue solid;
            font-size: 3.73vw;
          }
        }
      }
    }
    .footer-btm {
      margin: 0 auto;
      padding: 70px 10px 30px;
      max-width: 1200px;
      display: flex;
      color: #828282;
      ul {
        display: flex;
        font-size: 14px;
        li {
          margin-right: 30px;
          a {
            color: #828282;
          }
        }
      }
      span {
        margin: 0 0 0 auto;
        display: block;
        font-family: $font-en;
        font-size: 12px;
      }
    }
    @include media(sp) {
      .footer-btm {
        margin: 42.4vw auto 0;
        padding: 10.66vw 0 6.66vw;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        ul {
          margin-bottom: 6vw;
          display: flex;
          font-size: 2.93vw;
          li {
            margin-right: 6.6vw;
            justify-content: center;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        span {
          margin: 0;
          width: 100%;
          font-size: 2.13vw;
          text-align: center;
          display: block;
        }
      }
    }
  }
}
//scroll
.btm-scroll {
  position: fixed;
  right: 20px;
  bottom: 10px;
  z-index: 99;
  padding: 10px 10px 73px;
  line-height: 1;
  overflow: hidden;
  color: $color-gray1;
  font-size: 12px;
  font-family: 'Montserrat', '游ゴシック', YuGothic, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  writing-mode: vertical-lr;
  transition: 0.5s;
  &.hide {
    opacity: 0;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 1px);
    width: 2px;
    height: 60px;
    background: #e6e6e6;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 1px);
    width: 2px;
    height: 60px;
    background: $color-blue;
    animation: sdl 2.5s cubic-bezier(1, 0, 0, 1) infinite;
  }
  @keyframes sdl {
    0% {
      opacity: 1;
      transform: scale(1, 0);
      transform-origin: 0 0;
    }
    50% {
      transform: scale(1, 1);
      transform-origin: 0 0;
    }
    50.1%, 70% {
      transform: scale(1, 1);
      transform-origin: 0 100%;
    }
    100% {
      opacity: 0;
      transform: scale(1, 0);
      transform-origin: 0 100%;
    }
  }
  @include media(sp) {
    position: fixed;
    right: 4vw;
    bottom: 4vw;
    z-index: 99;
    padding: 1.33vw 1.33vw 12.5vw;
    font-size: 2.4vw;
    &::before {
      height: 10vw;
    }
    &::after {
      height: 10vw;
    }
  }
}

// body#home footer {
//   top: 100vh;
// }
