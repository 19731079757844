@use '../setting' as *;
@use '../mixin/mixin' as *;
@charset "UTF-8";
header {
  position: fixed;
  top: 0;
  width: 100%;
  background: $color-gray2;
  z-index: 999;
  transition: 1s;
  &.is-hide {
    transform: translateY(-100%);
  }
  #header-nav {
    padding: 0 30px;
    display: flex;
    align-items: center;
    height: 65px;
  }
  .header-nav-logo {
    margin-right: auto;
    width: 124px;
    img {
      width: 100%;
      height: auto;
    }
  }
  #header-nav-li {
    display: flex;
    li {
      margin-right: 50px;
      height: 44px;
      line-height: 44px;
      font-size: 15px;
      @include bold;
    }
    li.contact {
      margin-right: 0;
      a {
        @include btn-contact1;
        padding-right: 20px;
        width: 160px;
        height: 44px;
        line-height: 44px;
        &::before {
          top: 13px;
          left: 20px;
        }
      }
    }
  }
}
@include media(sp) {
  header {
    height: 12.53vw;
    background: #fff;
    #header-nav {
      padding: 0 4.53vw;
      height: 12.53vw;
    }
    .header-nav-logo {
      width: 20.8vw;
      z-index: 1000;
    }
    #header-nav-li {
      display: none;
    }
    .sp-nav-btn {
      display: block;
      position: absolute;
      top: 3.2vw;
      right: 4vw;
      width: 6.66vw;
      height: 6.66vw;
      z-index: 1000;
      cursor: pointer;
      span {
        display: block;
        width: 6.66vw;
        height: 1px;
        background: $color-blue;
        position: absolute;
        right: 0;
        transition: .3s ease-in-out;
        &:first-child {
          top: 1.6vw;
        }
        &:last-child {
          top: 4.53vw;
        }
      }
      &.is-active span:first-child {
        transform: translateY(1.5vw) rotate(-45deg);
        background: $color-blue;
      }
      &.is-active span:last-child {
        transform: translateY(-1.5vw) rotate(45deg);
        background: $color-blue;
      }
    }
    .sp-menu {
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 999;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      min-height: 100%;
      background: #fff;
      color: #fff;
      display: block;
      padding: 22.6vw 10.66vw 0;
      overflow-y: scroll;
      box-sizing: border-box;
      transition: .5s ease-in-out;
      transform: translate3d(0, -100%, 0);
      .sp-menu-open & {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
      ul {
        margin-bottom: 8.53vw;
        li a {
          padding-top: 4vw;
          border-bottom: 1px $color-gray3 solid;
          height: 9.33vw;
          line-height: 9.33vw;
          display: block;
          font-size: 3.6vw;
          font-weight: bold;
          position: relative;
          &::after {
            position: absolute;
            content: '>';
            font-size: 3.6vw;
            font-weight: 800;
            top: 4vw;
            right: 0;
            color: $color-gray1;
            transform: scale(0.5, 1)
          }
        }
        li:nth-child(3) a, li:nth-child(4) a {
          color: $color-blue;
          &::after {
            color: $color-blue;
          }
        }
        .sp-menu-open & {
          opacity: 1;
        }
      }
      .sp-menu-tel {
        padding-bottom: 10vw;
        span, a {
          font-weight: bold;
          color: $color-black;
          display: inline-block;
        }
        span {
          width: 100%;
        }
        span:first-child {
          margin-bottom: 2.66vw;
          font-size: 4.8vw;
          color: $color-blue;
        }
        span:nth-child(2) {
          margin-bottom: 2.66vw;
          font-size: 2.93vw;
        }
        a:nth-child(3) {
          margin-bottom: 2.66vw;
          padding-left: 4vw;
          font-size: 5.46vw;
          color: $color-blue;
          position: relative;
          &::before {
            position: absolute;
            content: '';
            width: 3.06vw;
            height: 4vw;
            background: url('/assets/images/common/ic_tel_blue.png') no-repeat;
            background-size: 3.06vw 4vw;
            top: 1vw;
            left: 0.5vw;
          }
        }
        span:nth-child(4) {
          font-size: 2.93vw;
          font-weight: normal;
        }
        .sp-menu-open & {
          opacity: 1;
        }
      }
      ul, .sp-menu-tel {
        opacity: 0;
        transition: 2s all;
      }
    }
  }
}

body.lower header {
  background: #f8f8f8;
}
