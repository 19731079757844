@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@500;600");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed");
@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre");
@import url("https://fonts.googleapis.com/css2?family=Montserrat");
/**
* YUI 3.5.0 - reset.css (http://developer.yahoo.com/yui/3/cssreset/)
* http://cssreset.com
* Copyright 2012 Yahoo! Inc. All rights reserved.
* http://yuilibrary.com/license/
*/
/*
TODO will need to remove settings on HTML since we can't namespace it.
TODO with the prefix, should I group by selector or property for weight savings?
*/
html {
  color: #000;
  background: #FFF;
}

/*
TODO remove settings on BODY since we can't namespace it.
*/
/*
TODO test putting a class on HEAD.
- Fails on FF.
*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

/*
TODO think about hanlding inheritence differently, maybe letting IE6 fail a bit...
*/
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: "";
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

/* to preserve line-height and selector appearance */
sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

/*to enable resizing for IE*/
input,
textarea,
select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

/* YUI CSS Detection Stamp */
#yui3-css-stamp.cssreset {
  display: none;
}

/* iOSでのデフォルトスタイルをリセット */
input[type=submit],
input[type=button] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
}
input[type=submit]::-webkit-search-decoration,
input[type=button]::-webkit-search-decoration {
  display: none;
}
input[type=submit]::focus,
input[type=button]::focus {
  outline-offset: -2px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/* Clearfix
------------------------*/
html {
  font-size: 62.5%;
}

body {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: auto;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 62.5%;
  font-weight: 500;
  color: #000000;
  font-feature-settings: "pkna";
  background-color: #fff;
  line-height: 1;
  position: relative;
  text-align: justify;
}

@media screen and (max-width: 999px) {
  body.sp-menu-open {
    height: 100%;
    overflow: hidden;
  }
}

.cf:after {
  content: "";
  display: block;
  clear: both;
}

a {
  text-decoration: none;
  color: #1e1e1e;
  transition: 0.3s all;
}
a:hover {
  opacity: 0.7;
}
@media screen and (min-width: 1000px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

a:focus, *:focus {
  outline: none;
}

p {
  line-height: 2;
  font-size: 16px;
}
@media screen and (max-width: 999px) {
  p {
    line-height: 1.8;
    font-size: 3.2vw;
  }
}

section {
  position: relative;
}

@media screen and (max-width: 999px) {
  .is-pc {
    display: none;
  }
}

.is-sp {
  display: none;
}
@media screen and (max-width: 999px) {
  .is-sp {
    display: block;
  }
}

.fade {
  transition: 0.3s ease-in-out;
}
.fade:hover {
  opacity: 0.7;
}

button.slick-arrow {
  margin: 0;
  border: none;
  width: 23px;
  height: 51px;
  font-size: 0;
}
button.slick-arrow:hover {
  transition: 0.3s all;
}
button.slick-arrow:hover:hover {
  opacity: 0.7;
}

button.slick-prev,
button.slick-prev:hover,
button.slick-prev:focus {
  left: -30px;
  background: url("/assets/images/common/ic_arrow_prev_pc.png") no-repeat;
}

button.slick-next,
button.slick-next:hover,
button.slick-next:focus {
  right: -30px;
  background: url("/assets/images/common/ic_arrow_next_pc.png") no-repeat;
}

button.slick-prev,
button.slick-next,
button.slick-prev:hover,
button.slick-next:hover,
button.slick-prev:focus,
button.slick-next:focus {
  background-size: 23px 51px;
  z-index: 1;
}

button.slick-prev:before,
button.slick-next:before {
  font-size: 0;
}

@media screen and (max-width: 999px) {
  button.slick-arrow {
    width: 8.6vw;
    height: 11.3vw;
  }
  button.slick-prev,
  button.slick-prev:hover,
  button.slick-prev:focus {
    left: 0;
    background: url("/assets/images/common/ic_arrow_prev_sp.png") no-repeat;
    background-position: 2.1vw;
  }
  button.slick-next,
  button.slick-next:hover,
  button.slick-next:focus {
    right: 0;
    background: url("/assets/images/common/ic_arrow_next_sp.png") no-repeat;
    background-position: 4.1vw;
  }
  button.slick-prev,
  button.slick-next,
  button.slick-prev:hover,
  button.slick-next:hover,
  button.slick-prev:focus,
  button.slick-next:focus {
    background-size: 2.4vw 4.53vw;
  }
}
#loading {
  width: 100vw;
  height: 100vh;
  transition: 1s all;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
}
#loading .loading-bg {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: absolute;
}
#loading.loaded {
  opacity: 0;
  visibility: hidden;
}
#loading .spinner {
  margin: auto;
  width: 40px;
  height: 40px;
  background: #003981;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10000;
}
@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #f4f4f4;
  z-index: 999;
  transition: 1s;
}
header.is-hide {
  transform: translateY(-100%);
}
header #header-nav {
  padding: 0 30px;
  display: flex;
  align-items: center;
  height: 65px;
}
header .header-nav-logo {
  margin-right: auto;
  width: 124px;
}
header .header-nav-logo img {
  width: 100%;
  height: auto;
}
header #header-nav-li {
  display: flex;
}
header #header-nav-li li {
  margin-right: 50px;
  height: 44px;
  line-height: 44px;
  font-size: 15px;
  color: #1e1e1e;
  font-weight: bold;
}
header #header-nav-li li.contact {
  margin-right: 0;
}
header #header-nav-li li.contact a {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 99px;
  box-sizing: border-box;
  font-weight: bold;
  background: #003981;
  color: #fff;
  text-align: right;
  position: relative;
  padding-right: 20px;
  width: 160px;
  height: 44px;
  line-height: 44px;
}
header #header-nav-li li.contact a:hover {
  opacity: 0.85;
}
header #header-nav-li li.contact a::before {
  width: 20px;
  height: 18px;
  content: "";
  background: url("/assets/images/common/ic_contact.png");
  background-size: 20px 18px;
  position: absolute;
  top: 13px;
  left: 20px;
}
header #header-nav-li li.contact a::before {
  top: 13px;
  left: 20px;
}

@media screen and (max-width: 999px) {
  header {
    height: 12.53vw;
    background: #fff;
  }
  header #header-nav {
    padding: 0 4.53vw;
    height: 12.53vw;
  }
  header .header-nav-logo {
    width: 20.8vw;
    z-index: 1000;
  }
  header #header-nav-li {
    display: none;
  }
  header .sp-nav-btn {
    display: block;
    position: absolute;
    top: 3.2vw;
    right: 4vw;
    width: 6.66vw;
    height: 6.66vw;
    z-index: 1000;
    cursor: pointer;
  }
  header .sp-nav-btn span {
    display: block;
    width: 6.66vw;
    height: 1px;
    background: #003981;
    position: absolute;
    right: 0;
    transition: 0.3s ease-in-out;
  }
  header .sp-nav-btn span:first-child {
    top: 1.6vw;
  }
  header .sp-nav-btn span:last-child {
    top: 4.53vw;
  }
  header .sp-nav-btn.is-active span:first-child {
    transform: translateY(1.5vw) rotate(-45deg);
    background: #003981;
  }
  header .sp-nav-btn.is-active span:last-child {
    transform: translateY(-1.5vw) rotate(45deg);
    background: #003981;
  }
  header .sp-menu {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    min-height: 100%;
    background: #fff;
    color: #fff;
    display: block;
    padding: 22.6vw 10.66vw 0;
    overflow-y: scroll;
    box-sizing: border-box;
    transition: 0.5s ease-in-out;
    transform: translate3d(0, -100%, 0);
  }
  .sp-menu-open header .sp-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  header .sp-menu ul {
    margin-bottom: 8.53vw;
  }
  header .sp-menu ul li a {
    padding-top: 4vw;
    border-bottom: 1px #dcdcdc solid;
    height: 9.33vw;
    line-height: 9.33vw;
    display: block;
    font-size: 3.6vw;
    font-weight: bold;
    position: relative;
  }
  header .sp-menu ul li a::after {
    position: absolute;
    content: ">";
    font-size: 3.6vw;
    font-weight: 800;
    top: 4vw;
    right: 0;
    color: #787878;
    transform: scale(0.5, 1);
  }
  header .sp-menu ul li:nth-child(3) a, header .sp-menu ul li:nth-child(4) a {
    color: #003981;
  }
  header .sp-menu ul li:nth-child(3) a::after, header .sp-menu ul li:nth-child(4) a::after {
    color: #003981;
  }
  .sp-menu-open header .sp-menu ul {
    opacity: 1;
  }
  header .sp-menu .sp-menu-tel {
    padding-bottom: 10vw;
  }
  header .sp-menu .sp-menu-tel span, header .sp-menu .sp-menu-tel a {
    font-weight: bold;
    color: #000000;
    display: inline-block;
  }
  header .sp-menu .sp-menu-tel span {
    width: 100%;
  }
  header .sp-menu .sp-menu-tel span:first-child {
    margin-bottom: 2.66vw;
    font-size: 4.8vw;
    color: #003981;
  }
  header .sp-menu .sp-menu-tel span:nth-child(2) {
    margin-bottom: 2.66vw;
    font-size: 2.93vw;
  }
  header .sp-menu .sp-menu-tel a:nth-child(3) {
    margin-bottom: 2.66vw;
    padding-left: 4vw;
    font-size: 5.46vw;
    color: #003981;
    position: relative;
  }
  header .sp-menu .sp-menu-tel a:nth-child(3)::before {
    position: absolute;
    content: "";
    width: 3.06vw;
    height: 4vw;
    background: url("/assets/images/common/ic_tel_blue.png") no-repeat;
    background-size: 3.06vw 4vw;
    top: 1vw;
    left: 0.5vw;
  }
  header .sp-menu .sp-menu-tel span:nth-child(4) {
    font-size: 2.93vw;
    font-weight: normal;
  }
  .sp-menu-open header .sp-menu .sp-menu-tel {
    opacity: 1;
  }
  header .sp-menu ul, header .sp-menu .sp-menu-tel {
    opacity: 0;
    transition: 2s all;
  }
}
body.lower header {
  background: #f8f8f8;
}

footer {
  position: relative;
  background: #fff;
}
footer .footer-top {
  padding-top: 150px;
  width: 100%;
  height: 560px;
  background: url("/assets/images/common/footer_bg_pc.jpg") no-repeat;
  background-size: cover;
  background-position: right;
  box-sizing: border-box;
}
footer .footer-top .footer-top-inner {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1000px;
  font-weight: bold;
  color: #fff;
}
footer .footer-top .footer-top-inner span {
  display: block;
}
footer .footer-top .footer-top-inner span:first-child {
  margin-bottom: 20px;
  font-size: 45px;
  text-indent: -8px;
}
footer .footer-top .footer-top-inner span:nth-child(2) {
  margin-bottom: 10px;
  font-size: 22px;
}
footer .footer-top .footer-top-inner a:nth-child(3) {
  margin-bottom: 15px;
  padding-left: 30px;
  font-size: 40px;
  color: #fff;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  position: relative;
  display: inline-block;
}
footer .footer-top .footer-top-inner a:nth-child(3)::before {
  width: 25px;
  height: 32px;
  position: absolute;
  content: "";
  background: url("/assets/images/common/ic_tel.png") no-repeat;
  background-size: 25px 32px;
  top: 8px;
  left: 0;
}
footer .footer-top .footer-top-inner span:nth-child(4) {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: normal;
}
footer .footer-top .footer-top-inner .footer-top-btn ul {
  display: flex;
}
footer .footer-top .footer-top-inner .footer-top-btn li {
  margin-right: 20px;
  width: 350px;
  height: 70px;
  line-height: 70px;
}
footer .footer-top .footer-top-inner .footer-top-btn li.contact a {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 99px;
  box-sizing: border-box;
  font-weight: bold;
  background: #003981;
  color: #fff;
  text-align: right;
  position: relative;
  padding-right: 80px;
  font-size: 22px;
}
footer .footer-top .footer-top-inner .footer-top-btn li.contact a:hover {
  opacity: 0.85;
}
footer .footer-top .footer-top-inner .footer-top-btn li.contact a::before {
  width: 20px;
  height: 18px;
  content: "";
  background: url("/assets/images/common/ic_contact.png");
  background-size: 20px 18px;
  position: absolute;
  top: 13px;
  left: 20px;
}
footer .footer-top .footer-top-inner .footer-top-btn li.contact a::before {
  width: 23px;
  height: 21px;
  background-size: 23px 21px;
  top: 25px;
  left: 85px;
}
@media screen and (max-width: 999px) {
  footer .footer-top .footer-top-inner .footer-top-btn li.contact a {
    padding-right: 29.3vw;
    font-size: 3.73vw;
  }
  footer .footer-top .footer-top-inner .footer-top-btn li.contact a::before {
    width: 3.86vw;
    height: 3.6vw;
    background: url("/assets/images/common/ic_contact.png");
    background-size: 3.86vw 3.6vw;
    top: 4.5vw;
    left: 26.6vw;
  }
}
footer .footer-top .footer-top-inner .footer-top-btn li.list a {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 99px;
  box-sizing: border-box;
  font-weight: bold;
  background: #fff;
  color: #003981;
  font-size: 18px;
}
footer .footer-top .footer-top-inner .footer-top-btn li.list a:hover {
  opacity: 0.8;
}
footer .footer-btm {
  margin: 0 auto;
  padding: 70px 10px 30px;
  max-width: 1200px;
  display: flex;
  color: #828282;
}
footer .footer-btm ul {
  display: flex;
  font-size: 14px;
}
footer .footer-btm ul li {
  margin-right: 30px;
}
footer .footer-btm ul li a {
  color: #828282;
}
footer .footer-btm span {
  margin: 0 0 0 auto;
  display: block;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 12px;
}
@media screen and (max-width: 999px) {
  footer {
    width: 100%;
  }
  footer .footer-top {
    padding-top: 54.66vw;
    width: 100%;
    height: 91.2vw;
    background: url("/assets/images/common/footer_bg_sp.jpg") no-repeat;
    background-size: cover;
  }
  footer .footer-top .footer-top-inner {
    margin: 0 auto;
    padding: 0;
    max-width: 100%;
  }
  footer .footer-top .footer-top-inner span:first-child {
    margin: 0 0 2.66vw 6.6vw;
    font-size: 8vw;
    text-indent: -1.3vw;
  }
  footer .footer-top .footer-top-inner span:nth-child(2) {
    margin: 0 0 2.66vw 6.6vw;
    font-size: 3.46vw;
  }
  footer .footer-top .footer-top-inner a:nth-child(3) {
    margin: 0 0 2.66vw 6.6vw;
    padding-left: 5vw;
    font-size: 7.33vw;
  }
  footer .footer-top .footer-top-inner a:nth-child(3)::before {
    width: 4.13vw;
    height: 5.33vw;
    background: url("/assets/images/common/ic_tel.png") no-repeat;
    background-size: 4.13vw 5.33vw;
    top: 1vw;
    left: 0.5vw;
  }
  footer .footer-top .footer-top-inner span:nth-child(4) {
    margin: 0 0 7vw 6.6vw;
    font-size: 3.2vw;
  }
  footer .footer-top .footer-top-inner .footer-top-btn {
    margin-bottom: 12.53vw;
    padding: 6.66vw;
    position: relative;
    top: 0;
    left: 0;
    background: #f4f4f4;
    box-sizing: border-box;
  }
  footer .footer-top .footer-top-inner .footer-top-btn ul {
    display: block;
  }
  footer .footer-top .footer-top-inner .footer-top-btn li {
    margin: 0 0 4vw;
    width: 100%;
    height: 12.53vw;
    line-height: 12.53vw;
  }
  footer .footer-top .footer-top-inner .footer-top-btn li:last-child {
    margin-bottom: 0;
  }
  footer .footer-top .footer-top-inner .footer-top-btn li.contact a {
    padding-right: 29.33vw;
  }
  footer .footer-top .footer-top-inner .footer-top-btn li.contact a::before {
    left: 26.93vw;
  }
  footer .footer-top .footer-top-inner .footer-top-btn li.list a {
    border: 2px #003981 solid;
    font-size: 3.73vw;
  }
  footer .footer-btm {
    margin: 0 auto;
    padding: 70px 10px 30px;
    max-width: 1200px;
    display: flex;
    color: #828282;
  }
  footer .footer-btm ul {
    display: flex;
    font-size: 14px;
  }
  footer .footer-btm ul li {
    margin-right: 30px;
  }
  footer .footer-btm ul li a {
    color: #828282;
  }
  footer .footer-btm span {
    margin: 0 0 0 auto;
    display: block;
    font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
    font-size: 12px;
  }
}
@media screen and (max-width: 999px) and (max-width: 999px) {
  footer .footer-btm {
    margin: 42.4vw auto 0;
    padding: 10.66vw 0 6.66vw;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  footer .footer-btm ul {
    margin-bottom: 6vw;
    display: flex;
    font-size: 2.93vw;
  }
  footer .footer-btm ul li {
    margin-right: 6.6vw;
    justify-content: center;
  }
  footer .footer-btm ul li:last-child {
    margin-right: 0;
  }
  footer .footer-btm span {
    margin: 0;
    width: 100%;
    font-size: 2.13vw;
    text-align: center;
    display: block;
  }
}

.btm-scroll {
  position: fixed;
  right: 20px;
  bottom: 10px;
  z-index: 99;
  padding: 10px 10px 73px;
  line-height: 1;
  overflow: hidden;
  color: #787878;
  font-size: 12px;
  font-family: "Montserrat", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  writing-mode: vertical-lr;
  transition: 0.5s;
}
.btm-scroll.hide {
  opacity: 0;
}
.btm-scroll::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(50% - 1px);
  width: 2px;
  height: 60px;
  background: #e6e6e6;
}
.btm-scroll::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(50% - 1px);
  width: 2px;
  height: 60px;
  background: #003981;
  animation: sdl 2.5s cubic-bezier(1, 0, 0, 1) infinite;
}
@keyframes sdl {
  0% {
    opacity: 1;
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50.1%, 70% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    opacity: 0;
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}
@media screen and (max-width: 999px) {
  .btm-scroll {
    position: fixed;
    right: 4vw;
    bottom: 4vw;
    z-index: 99;
    padding: 1.33vw 1.33vw 12.5vw;
    font-size: 2.4vw;
  }
  .btm-scroll::before {
    height: 10vw;
  }
  .btm-scroll::after {
    height: 10vw;
  }
}

section.contact {
  padding-top: 125px;
  width: 100%;
  height: 275px;
  background: url("/assets/images/home/cv_bg_pc.jpg") no-repeat center;
  background-size: cover;
  text-align: center;
}
section.contact h2 {
  margin-bottom: 45px;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
}
section.contact .contact-btn {
  display: flex;
  justify-content: center;
}
section.contact .contact-btn li {
  width: 350px;
  height: 70px;
}
section.contact .contact-btn li a {
  line-height: 70px;
  font-size: 22px;
}
section.contact .contact-btn li:first-child a {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 99px;
  box-sizing: border-box;
  font-weight: bold;
  background: #003981;
  color: #fff;
  text-align: right;
  position: relative;
  padding-right: 80px;
  font-size: 22px;
}
section.contact .contact-btn li:first-child a:hover {
  opacity: 0.85;
}
section.contact .contact-btn li:first-child a::before {
  width: 20px;
  height: 18px;
  content: "";
  background: url("/assets/images/common/ic_contact.png");
  background-size: 20px 18px;
  position: absolute;
  top: 13px;
  left: 20px;
}
section.contact .contact-btn li:first-child a::before {
  width: 23px;
  height: 21px;
  background-size: 23px 21px;
  top: 25px;
  left: 85px;
}
@media screen and (max-width: 999px) {
  section.contact .contact-btn li:first-child a {
    padding-right: 29.3vw;
    font-size: 3.73vw;
  }
  section.contact .contact-btn li:first-child a::before {
    width: 3.86vw;
    height: 3.6vw;
    background: url("/assets/images/common/ic_contact.png");
    background-size: 3.86vw 3.6vw;
    top: 4.5vw;
    left: 26.6vw;
  }
}
section.contact .contact-btn li:last-child {
  margin-left: 40px;
}
section.contact .contact-btn li:last-child a {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 99px;
  box-sizing: border-box;
  font-weight: bold;
  background: #fff;
  color: #003981;
  font-size: 18px;
}
section.contact .contact-btn li:last-child a:hover {
  opacity: 0.8;
}
@media screen and (max-width: 999px) {
  section.contact {
    padding: 20vw 6.6vw 0;
    height: 80vw;
    background: url("/assets/images/home/cv_bg_sp.jpg") no-repeat center;
    background-size: cover;
    box-sizing: border-box;
  }
  section.contact h2 {
    margin-bottom: 5.3vw;
    font-size: 6.66vw;
  }
  section.contact .contact-btn {
    display: block;
  }
  section.contact .contact-btn li {
    width: 100%;
    height: 12.5vw;
  }
  section.contact .contact-btn li a {
    line-height: 12.5vw;
  }
  section.contact .contact-btn li:last-child {
    margin: 4vw 0 0;
  }
  section.contact .contact-btn li:last-child a {
    font-size: 3.73vw;
  }
}

body#home main .home-bg {
  padding-top: 65px;
  width: 100%;
  background-image: url("/assets/images/home/mv3_pc_02.png");
  background-size: cover;
  background-position: center;
}
@media (max-width: 1270px) and (min-width: 1201px) {
  body#home main .home-bg {
    padding: 120px 0 80px 0;
  }
}
@media (max-width: 1200px) and (min-width: 1000px) {
  body#home main .home-bg {
    padding: 120px 0 60px 0;
  }
}
@media screen and (max-width: 999px) {
  body#home main .home-bg {
    padding: 80px 0 0 0;
  }
}
@media (max-width: 999px) and (min-width: 600px) {
  body#home main .home-bg {
    padding: 160px 0 80px 0;
  }
}
body#home main .home-top {
  margin: 0 auto;
  max-width: 1800px;
  position: relative;
  box-sizing: border-box;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 5%;
  padding-right: 5%;
}
@media (max-width: 1800px) and (min-width: 1301px) {
  body#home main .home-top {
    height: 70vh;
  }
}
@media (max-width: 1270px) {
  body#home main .home-top {
    display: block;
    height: auto;
  }
}
@media (max-width: 1000px) {
  body#home main .home-top {
    display: block;
    height: 45vh;
  }
}
@media (max-width: 767px) {
  body#home main .home-top {
    display: block;
    height: 62vh;
  }
}
@media (max-width: 376px) {
  body#home main .home-top {
    display: block;
    height: 75vh;
  }
}
body#home main .home-top .home-top-img {
  flex: 1;
  display: block;
  max-width: 1200px;
  min-width: 640px;
  margin-right: 5vw;
}
body#home main .home-top .home-top-img .home-top-img-pc {
  display: block;
}
body#home main .home-top .home-top-img .home-top-img-sp {
  display: none;
}
@media (max-width: 1500px) {
  body#home main .home-top .home-top-img {
    min-width: 500px;
  }
}
@media (max-width: 1270px) {
  body#home main .home-top .home-top-img {
    margin: 0 auto;
    text-align: center;
  }
}
@media (max-width: 767px) {
  body#home main .home-top .home-top-img {
    max-width: 100%;
    width: 100%;
    min-width: 0 !important;
  }
  body#home main .home-top .home-top-img .home-top-img-pc {
    display: none;
  }
  body#home main .home-top .home-top-img .home-top-img-sp {
    display: block;
    width: 100%;
  }
}
body#home main .home-top .home-top-img img {
  max-width: 800px;
  width: 100%;
  height: auto;
}
@media (min-width: 768px) and (max-width: 1270px) {
  body#home main .home-top .home-top-img img {
    max-width: 600px;
  }
}
body#home main .home-top .home-contact {
  flex: 1;
  margin-top: 210px;
  height: auto;
  max-height: 180px;
}
@media (max-width: 1270px) {
  body#home main .home-top .home-contact {
    margin-top: 40px;
  }
}
@media screen and (max-width: 999px) {
  body#home main .home-top .home-contact {
    padding: 0 6.6vw 0;
    height: auto;
    box-sizing: border-box;
  }
  body#home main .home-top .home-contact h2 {
    margin-bottom: 5.3vw;
    font-size: 6.66vw;
  }
  body#home main .home-top .home-contact .home-btn-list {
    flex-direction: column;
  }
  body#home main .home-top .home-contact .home-btn-list li {
    width: 100%;
    height: 12.5vw;
  }
  body#home main .home-top .home-contact .home-btn-list li a {
    line-height: 12.5vw;
  }
  body#home main .home-top .home-contact .home-btn-list li:last-child {
    margin: 0;
  }
  body#home main .home-top .home-contact .home-btn-list li:last-child a {
    font-size: 18px;
  }
}
body#home main .home-top .home-contact .home-btn-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
body#home main .home-top .home-contact .home-btn-list li {
  width: 350px;
  height: 70px;
}
@media (max-width: 1800px) {
  body#home main .home-top .home-contact .home-btn-list li {
    width: 300px;
    height: 60px;
  }
}
@media (max-width: 1300px) {
  body#home main .home-top .home-contact .home-btn-list li {
    width: 260px;
    height: 80px;
  }
}
@media (max-width: 700px) {
  body#home main .home-top .home-contact .home-btn-list li {
    height: 60px;
  }
}
body#home main .home-top .home-contact .home-btn-list li a {
  line-height: 70px;
  font-size: 22px;
  text-align: center;
}
@media (max-width: 1270px) and (min-width: 600px) {
  body#home main .home-top .home-contact .home-btn-list li:first-child {
    margin-right: 20px;
  }
}
@media screen and (max-width: 999px) {
  body#home main .home-top .home-contact .home-btn-list li:first-child {
    margin: 0;
  }
}
body#home main .home-top .home-contact .home-btn-list li:first-child a {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 99px;
  box-sizing: border-box;
  font-weight: bold;
  background: #003981;
  color: #fff;
  text-align: right;
  position: relative;
  padding-right: 80px;
  font-size: 22px;
}
body#home main .home-top .home-contact .home-btn-list li:first-child a:hover {
  opacity: 0.85;
}
body#home main .home-top .home-contact .home-btn-list li:first-child a::before {
  width: 20px;
  height: 18px;
  content: "";
  background: url("/assets/images/common/ic_contact.png");
  background-size: 20px 18px;
  position: absolute;
  top: 13px;
  left: 20px;
}
body#home main .home-top .home-contact .home-btn-list li:first-child a::before {
  width: 23px;
  height: 21px;
  background-size: 23px 21px;
  top: 25px;
  left: 85px;
}
@media screen and (max-width: 999px) {
  body#home main .home-top .home-contact .home-btn-list li:first-child a {
    padding-right: 29.3vw;
    font-size: 3.73vw;
  }
  body#home main .home-top .home-contact .home-btn-list li:first-child a::before {
    width: 3.86vw;
    height: 3.6vw;
    background: url("/assets/images/common/ic_contact.png");
    background-size: 3.86vw 3.6vw;
    top: 4.5vw;
    left: 26.6vw;
  }
}
body#home main .home-top .home-contact .home-btn-list li:first-child a::before {
  content: none;
}
body#home main .home-top .home-contact .home-btn-list li:first-child a {
  text-align: center;
  font-size: 18px;
  padding-right: 0;
}
@media (max-width: 1800px) {
  body#home main .home-top .home-contact .home-btn-list li:first-child a {
    font-size: 18px;
  }
}
@media screen and (max-width: 999px) {
  body#home main .home-top .home-contact .home-btn-list li:first-child a {
    text-align: center;
    padding-right: 0;
    font-size: 3.73vw !important;
  }
}
body#home main .home-top .home-contact .home-btn-list li:last-child {
  margin-left: 40px;
}
body#home main .home-top .home-contact .home-btn-list li:last-child a {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 99px;
  box-sizing: border-box;
  font-weight: bold;
  background: #003981;
  color: #fff;
  text-align: right;
  position: relative;
  padding-right: 80px;
  font-size: 22px;
  background: #fff;
  color: #003981;
  outline: 2px solid #003981;
}
body#home main .home-top .home-contact .home-btn-list li:last-child a:hover {
  opacity: 0.85;
}
body#home main .home-top .home-contact .home-btn-list li:last-child a::before {
  width: 20px;
  height: 18px;
  content: "";
  background: url("/assets/images/common/ic_contact.png");
  background-size: 20px 18px;
  position: absolute;
  top: 13px;
  left: 20px;
}
body#home main .home-top .home-contact .home-btn-list li:last-child a::before {
  width: 23px;
  height: 21px;
  background-size: 23px 21px;
  top: 25px;
  left: 85px;
}
@media screen and (max-width: 999px) {
  body#home main .home-top .home-contact .home-btn-list li:last-child a {
    padding-right: 29.3vw;
    font-size: 3.73vw;
  }
  body#home main .home-top .home-contact .home-btn-list li:last-child a::before {
    width: 3.86vw;
    height: 3.6vw;
    background: url("/assets/images/common/ic_contact.png");
    background-size: 3.86vw 3.6vw;
    top: 4.5vw;
    left: 26.6vw;
  }
}
body#home main .home-top .home-contact .home-btn-list li:last-child a {
  text-align: center;
  font-size: 18px;
  padding-right: 0;
}
@media screen and (max-width: 999px) {
  body#home main .home-top .home-contact .home-btn-list li:last-child a {
    padding-right: 0;
  }
}
body#home main .home-top .home-contact .home-btn-list li:last-child a::before {
  content: none;
}
@media (max-width: 1270px) and (min-width: 600px) {
  body#home main .home-top .home-contact .home-btn-list li:last-child {
    margin-left: 20px;
  }
}
@media (max-width: 1000px) and (min-width: 600px) {
  body#home main .home-top .home-contact .home-btn-list li:last-child {
    margin-top: 0;
  }
}
@media screen and (max-width: 999px) {
  body#home main .home-top .home-contact .home-btn-list li:last-child {
    margin: 0;
  }
}
@media (max-width: 1000px) and (min-width: 600px) {
  body#home main .home-top .home-contact .home-btn-list {
    display: flex;
    flex-direction: row;
  }
}
body#home main .result-top .result-inner {
  overflow: hidden;
}
body#home main .result-top .result-inner .scrolling-logos {
  margin-top: 40px;
  display: flex;
  gap: 40px;
  animation: scroll 20s linear infinite; /* 横スクロールアニメーションを設定 */
}
body#home main .result-top .result-inner .scrolling-logos:hover {
  animation-play-state: paused;
}
body#home main .result-top .result-inner .scrolling-logos li {
  display: inline-block;
  flex-shrink: 0;
}
body#home main .result-top .result-inner .scrolling-logos img {
  width: auto;
  height: 3.7vh;
  display: block;
  object-fit: contain;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-90%);
  }
}
@media screen and (max-width: 999px) {
  body#home main .result-top .result-inner {
    padding: 0;
  }
  body#home main .result-top .result-inner .scrolling-logos {
    margin-top: 20px;
    animation: scroll 20s linear infinite; /* 横スクロールアニメーションを設定 */
    gap: 20px;
  }
  body#home main .result-top .result-inner .scrolling-logos img {
    height: 2vh;
  }
}
body#home main .home-mv {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  overflow: hidden;
}
body#home main .home-mv .home-mv-ph {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  animation: bgAnime 15s infinite;
  background-size: cover;
  background-position: center;
}
body#home main .home-mv .ph1 {
  background-image: url("/assets/images/home/mv1_pc_02.png");
}
body#home main .home-mv .ph2 {
  background-image: url("/assets/images/home/mv2_pc_02.png");
  animation-delay: 5s;
}
body#home main .home-mv .ph3 {
  background-image: url("/assets/images/home/mv3_pc_02.png");
  animation-delay: 10s;
}
@keyframes bgAnime {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
body#home main .home-mv h1 {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 10px;
  padding-top: 58vh;
  max-width: 1200px;
  font-size: 26px;
  color: #1e1e1e;
  font-weight: bold;
  position: relative;
}
body#home main .home-mv h1 span {
  margin-bottom: 20px;
  display: block;
  width: 424px;
  height: 61px;
}
body#home main .home-mv h1 span img {
  width: 100%;
}
body#home main .wrap {
  background: #fff;
  position: relative;
}
@media screen and (max-width: 999px) {
  body#home main .home-mv .ph1 {
    background-image: url("/assets/images/home/mv1_sp_02.png");
  }
  body#home main .home-mv .ph2 {
    background-image: url("/assets/images/home/mv2_sp_02.png");
  }
  body#home main .home-mv .ph3 {
    background-image: url("/assets/images/home/mv3_sp_02.png");
  }
  body#home main .home-mv h1 {
    padding: 0 25px;
    padding-top: 58.2vh;
    max-width: 100%;
    font-size: 3.73vw;
    letter-spacing: 1px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
  }
  body#home main .home-mv h1 span {
    width: 64.66vw;
    height: 9.33vw;
  }
}
body#home section.intro {
  padding: 50px 0;
  background: url("/assets/images/home/intro_bg1_pc.png") no-repeat;
  background-size: 1426px 540px;
  background-position: left calc(50% + 713px) top 108px;
}
body#home section.intro .intro-inner {
  margin: 0 auto;
  max-width: 1300px;
  height: 700px;
  position: relative;
}
body#home section.intro .intro-img {
  width: 590px;
  height: 700px;
  position: absolute;
  top: 0;
  left: auto;
}
body#home section.intro img {
  width: 100%;
}
body#home section.intro h1 {
  width: 600px;
  line-height: 1.57;
  color: #003981;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 180px;
  right: -100px;
  z-index: 1;
}
body#home section.intro h2 {
  width: 600px;
  line-height: 1.57;
  color: #003981;
  font-size: 42px;
  font-weight: bold;
  position: absolute;
  top: 220px;
  right: 0;
  z-index: 1;
}
body#home section.intro h2 span {
  margin-bottom: 10px;
  display: block;
  font-size: 28px;
}
body#home section.intro span {
  margin-bottom: 10px;
  display: block;
  width: 600px;
  line-height: 1.57;
  color: #003981;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 180px;
  right: 0;
  z-index: 1;
}
body#home section.intro .intro-txt {
  width: 600px;
  position: absolute;
  top: 380px;
  right: 0;
  z-index: 1;
}
@media screen and (min-width: 1000px) and (max-width: 1230px) {
  body#home section.intro .intro-img {
    right: 660px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
  body#home section.intro {
    background: url("/assets/images/home/intro_bg2_pc.png") no-repeat;
    background-position: right 0 top 108px;
    background-size: 650px 540px;
  }
  body#home section.intro h2, body#home section.intro .intro-txt {
    right: 0;
  }
}
@media screen and (max-width: 999px) {
  body#home section.intro {
    padding: 21vw 0 25vw;
    max-width: 100%;
    height: auto;
    background: none;
  }
  body#home section.intro .intro-inner {
    height: auto;
  }
  body#home section.intro .intro-img {
    width: 72%;
    height: auto;
    position: static;
  }
  body#home section.intro h1, body#home section.intro h2, body#home section.intro span {
    width: 100%;
    line-height: 1.44;
    font-size: 6.93vw;
    position: static;
    box-sizing: border-box;
  }
  body#home section.intro h1 span, body#home section.intro h2 span, body#home section.intro span span {
    font-size: 4vw;
  }
  body#home section.intro h1, body#home section.intro span {
    font-size: 4vw;
    display: inline;
  }
  body#home section.intro h2 {
    margin-top: 10px;
    margin-bottom: 6.6vw;
    padding: 0 6.6vw;
  }
  body#home section.intro span {
    padding-left: 6.6vw;
  }
  body#home section.intro .intro-txt {
    margin-top: 10.6vw;
    padding: 0 6.6vw;
    width: 100%;
    position: static;
    box-sizing: border-box;
  }
}
body#home section.voice {
  padding: 95px 0 115px;
  background: url("/assets/images/common/bg_border_pc.png") repeat;
  background-size: 1px 4px;
}
@media screen and (max-width: 999px) {
  body#home section.voice {
    background: url("/assets/images/common/bg_border_sp.png") repeat;
    background-size: 1px 4px;
  }
}
body#home section.voice h2 {
  margin-bottom: 60px;
  text-align: center;
  color: #003981;
  font-size: 36px;
  font-weight: bold;
}
body#home section.voice h2 span {
  margin-bottom: 15px;
  display: block;
  color: #787878;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 20px;
}
@media screen and (max-width: 999px) {
  body#home section.voice h2 {
    margin-bottom: 8.5vw;
    font-size: 5.33vw;
  }
  body#home section.voice h2 span {
    margin-bottom: 1.3vw;
    font-size: 2.66vw;
  }
}
body#home section.voice .voice-inner {
  margin: 0 auto;
  max-width: 1200px;
}
body#home section.voice .voice-content {
  padding: 5px 5px 5px 15px;
  display: flex;
  width: 100%;
}
body#home section.voice .voice-content li {
  width: calc(33.3333333333% - 40px);
  display: flex;
  transition: 0.3s all;
}
body#home section.voice .voice-content li:hover {
  opacity: 0.7;
}
body#home section.voice .voice-content li .slide-li {
  margin: 5px 15px 5px 5px;
  background: #fff;
  box-shadow: 0 0 8px #c5c5c5;
  box-sizing: border-box;
}
body#home section.voice .voice-content li .slide-li img {
  width: 100%;
}
body#home section.voice .voice-content li .slide-txt {
  padding: 30px 35px;
}
body#home section.voice .voice-content li .slide-txt h3 {
  margin-bottom: 30px;
  line-height: 1.8;
  color: #003981;
  font-size: 20px;
  font-weight: bold;
}
@media screen and (min-width: 1000px) {
  body#home section.voice .voice-content li .slide-li {
    display: flex;
  }
  body#home section.voice .voice-content li a {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  body#home section.voice .voice-content li a .slide-ph img {
    width: 100%;
  }
  body#home section.voice .voice-content li a .slide-txt {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1270px) {
  body#home section.voice .voice-content button.slick-prev {
    left: 0;
  }
  body#home section.voice .voice-content button.slick-next {
    right: 0;
  }
}
@media screen and (max-width: 999px) {
  body#home section.voice .voice-content {
    padding: 0;
    display: block;
  }
  body#home section.voice .voice-content li {
    width: 100%;
  }
  body#home section.voice .voice-content li .slide-li {
    margin: 0 0 4vw;
  }
  body#home section.voice .voice-content li .slide-li a {
    display: flex;
    flex-wrap: nowrap;
  }
  body#home section.voice .voice-content li .slide-ph {
    width: 40vw;
    height: 40vw;
  }
  body#home section.voice .voice-content li .slide-ph img {
    width: 100%;
  }
  body#home section.voice .voice-content li .slide-txt {
    padding: 5.5vw 5.6vw 5vw;
    width: calc(100% - 40vw);
    box-sizing: border-box;
  }
  body#home section.voice .voice-content li .slide-txt h3 {
    margin-bottom: 3.2vw;
    font-size: 3.2vw;
  }
  body#home section.voice .voice-content li .slide-txt p {
    font-size: 2.66vw;
  }
}
body#home section.voice .voice-content li .slide-txt .slide-txt-logo {
  margin-top: auto;
  width: 220px;
}
body#home section.voice .voice-content li .slide-txt .slide-txt-logo img {
  width: 100%;
}
body#home section.voice .voice-content li .slide-txt p {
  margin-top: 5px;
}
@media screen and (max-width: 999px) {
  body#home section.voice {
    padding: 13vw 6.6vw;
    overflow: hidden;
  }
  body#home section.voice .voice-inner {
    max-width: auto;
  }
  body#home section.voice .voice-content {
    background: #fff;
    box-shadow: 0 0 8px #c5c5c5;
  }
  body#home section.voice .voice-content button.slick-prev,
  body#home section.voice .voice-content button.slick-prev:hover,
  body#home section.voice .voice-content button.slick-prev:focus {
    left: -6.6vw;
  }
  body#home section.voice .voice-content button.slick-next,
  body#home section.voice .voice-content button.slick-next:hover,
  body#home section.voice .voice-content button.slick-next:focus {
    right: -6.6vw;
  }
  body#home section.voice .voice-content li .slide-li {
    margin-bottom: 0;
    box-shadow: none;
  }
  body#home section.voice .voice-content li .slide-txt {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  body#home section.voice .voice-content li .slide-txt h3 {
    width: 100%;
  }
  body#home section.voice .voice-content li .slide-txt .slide-txt-logo {
    width: 24vw;
  }
}
body#home section.result {
  padding: 130px 0;
}
body#home section.result h2 {
  margin-bottom: 50px;
  line-height: 1.8;
  text-align: center;
  font-size: 24px;
  color: #1e1e1e;
  font-weight: bold;
}
body#home section.result h2 span {
  color: #003981;
  font-size: 36px;
}
body#home section.result h2 span span {
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 50px;
}
body#home section.result .result-inner {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1200px;
}
body#home section.result .result-inner img {
  width: 100%;
}
body#home section.result .result-inner ul {
  margin: 0 auto 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
body#home section.result .result-inner ul:nth-child(1) li:nth-child(1) {
  width: 158px;
}
body#home section.result .result-inner ul:nth-child(1) li:nth-child(2) {
  width: 98px;
}
body#home section.result .result-inner ul:nth-child(1) li:nth-child(3) {
  width: 120px;
}
body#home section.result .result-inner ul:nth-child(1) li:nth-child(4) {
  width: 173px;
}
body#home section.result .result-inner ul:nth-child(1) li:nth-child(5) {
  width: 112px;
}
body#home section.result .result-inner ul:nth-child(1) li:nth-child(6) {
  width: 187px;
}
body#home section.result .result-inner ul:nth-child(2) {
  max-width: 1140px;
}
body#home section.result .result-inner ul:nth-child(2) li:nth-child(1) {
  width: 110px;
}
body#home section.result .result-inner ul:nth-child(2) li:nth-child(2) {
  width: 146px;
}
body#home section.result .result-inner ul:nth-child(2) li:nth-child(3) {
  width: 87px;
}
body#home section.result .result-inner ul:nth-child(2) li:nth-child(4) {
  width: 75px;
}
body#home section.result .result-inner ul:nth-child(2) li:nth-child(5) {
  width: 117px;
}
body#home section.result .result-inner ul:nth-child(2) li:nth-child(6) {
  width: 118px;
}
body#home section.result .result-inner ul:nth-child(3) {
  margin-bottom: 0;
  max-width: 1120px;
}
body#home section.result .result-inner ul:nth-child(3) li:nth-child(1) {
  width: 99px;
}
body#home section.result .result-inner ul:nth-child(3) li:nth-child(2) {
  width: 141px;
}
body#home section.result .result-inner ul:nth-child(3) li:nth-child(3) {
  width: 131px;
}
body#home section.result .result-inner ul:nth-child(3) li:nth-child(4) {
  width: 123px;
}
body#home section.result .result-inner ul:nth-child(3) li:nth-child(5) {
  width: 182px;
}
body#home section.result .result-inner ul:nth-child(3) li:nth-child(6) {
  width: 79.5px;
}
@media screen and (max-width: 999px) {
  body#home section.result {
    padding: 16vw 4vw;
  }
  body#home section.result h2 {
    margin-bottom: 8vw;
    font-size: 3.46vw;
    font-weight: bold;
  }
  body#home section.result h2 span {
    font-size: 4.8vw;
  }
  body#home section.result h2 span span {
    font-size: 6.66vw;
  }
  body#home section.result .result-inner {
    padding: 0;
    max-width: auto;
  }
  body#home section.result .result-inner ul {
    margin-bottom: 0;
    align-items: center;
  }
  body#home section.result .result-inner ul li {
    margin-bottom: 6.6vw;
    width: 33.3333333333% !important;
  }
  body#home section.result .result-inner ul li img {
    margin: 0 auto;
    display: block;
  }
  body#home section.result .result-inner ul:nth-child(1) li:nth-child(1) img {
    width: 23.2vw;
  }
  body#home section.result .result-inner ul:nth-child(1) li:nth-child(2) img {
    width: 15.73vw;
  }
  body#home section.result .result-inner ul:nth-child(1) li:nth-child(3) img {
    width: 18.4vw;
  }
  body#home section.result .result-inner ul:nth-child(1) li:nth-child(4) img {
    width: 25.46vw;
  }
  body#home section.result .result-inner ul:nth-child(1) li:nth-child(5) img {
    width: 16.8vw;
  }
  body#home section.result .result-inner ul:nth-child(1) li:nth-child(6) img {
    width: 23.3vw;
  }
  body#home section.result .result-inner ul:nth-child(2) {
    width: 100%;
  }
  body#home section.result .result-inner ul:nth-child(2) li:nth-child(1) img {
    width: 15.6vw;
  }
  body#home section.result .result-inner ul:nth-child(2) li:nth-child(2) img {
    width: 20.8vw;
  }
  body#home section.result .result-inner ul:nth-child(2) li:nth-child(3) img {
    width: 12.93vw;
  }
  body#home section.result .result-inner ul:nth-child(2) li:nth-child(4) img {
    width: 11.86vw;
  }
  body#home section.result .result-inner ul:nth-child(2) li:nth-child(5) img {
    width: 16.8vw;
  }
  body#home section.result .result-inner ul:nth-child(2) li:nth-child(6) img {
    width: 17.2vw;
  }
  body#home section.result .result-inner ul:nth-child(3) {
    width: 100%;
  }
  body#home section.result .result-inner ul:nth-child(3) li:nth-child(1) img {
    width: 13.86vw;
  }
  body#home section.result .result-inner ul:nth-child(3) li:nth-child(2) img {
    width: 20.66vw;
  }
  body#home section.result .result-inner ul:nth-child(3) li:nth-child(3) img {
    width: 18.93vw;
  }
  body#home section.result .result-inner ul:nth-child(3) li:nth-child(4) img {
    width: 17.73vw;
  }
  body#home section.result .result-inner ul:nth-child(3) li:nth-child(5) img {
    width: 24.93vw;
  }
  body#home section.result .result-inner ul:nth-child(3) li:nth-child(6) img {
    width: 12vw;
  }
}
body#home section.feature {
  padding: 75px 0 145px;
}
body#home section.feature h2 {
  margin: 0 auto 80px;
  padding-top: 180px;
  max-width: 1400px;
  height: 280px;
  line-height: 1.8;
  background: url("/assets/images/home/feature_bg_pc.jpg") no-repeat center;
  background-size: cover;
  text-align: center;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 20px;
  color: #1e1e1e;
  font-weight: bold;
}
body#home section.feature h2 span {
  color: #003981;
  font-size: 40px;
}
body#home section.feature h2 span span {
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 50px;
}
body#home section.feature h2 span span.img {
  margin-right: 3px;
  width: 236px;
  height: 39px;
  display: inline-block;
}
body#home section.feature h2 span span.img img {
  width: 100%;
  vertical-align: middle;
}
body#home section.feature .feature-wrap {
  background: url("/assets/images/home/feature_bg1_pc.png") no-repeat, url("/assets/images/home/feature_bg2_pc.png") no-repeat;
  background-position: right 0 top 190px, left 0 top 840px;
  background-size: 1100px 521px, 1100px 437px;
}
body#home section.feature .feature-inner {
  margin: 0 auto;
  max-width: 1400px;
  background: url("/assets/images/home/feature_bg_num1.png") no-repeat, url("/assets/images/home/feature_bg_num2.png") no-repeat, url("/assets/images/home/feature_bg_num3.png") no-repeat;
  background-position: right 110px top 105px, left 480px top 600px, right 100px top 1180px;
  background-size: 110px 198px, 141px 202px, 133px 204px;
}
body#home section.feature .feature-inner h3 {
  margin-bottom: 55px;
  font-size: 24px;
  color: #1e1e1e;
  font-weight: bold;
}
body#home section.feature .feature-inner h3 span {
  margin-left: -20px;
  font-size: 65px;
  color: #003981;
  letter-spacing: -3px;
}
body#home section.feature .feature-inner .feature-block-ph img {
  width: 100%;
}
body#home section.feature .feature-inner .feature-block1 {
  margin-bottom: 50px;
  height: 452px;
}
body#home section.feature .feature-inner .feature-block1 h3 {
  margin-top: 170px;
  float: right;
  text-align: left;
}
body#home section.feature .feature-inner .feature-block1 .feature-block-ph {
  float: left;
  width: 700px;
  height: 452px;
}
body#home section.feature .feature-inner .feature-block1 .feature-block-txt {
  float: right;
}
body#home section.feature .feature-inner .feature-block1 h3,
body#home section.feature .feature-inner .feature-block1 .feature-block-txt {
  padding-right: 70px;
  width: 580px;
}
body#home section.feature .feature-inner .feature-block2 {
  margin-bottom: 120px;
  height: 542px;
}
body#home section.feature .feature-inner .feature-block2 h3 {
  margin: 175px 0 35px;
  float: left;
}
body#home section.feature .feature-inner .feature-block2 .feature-block-ph {
  float: right;
  width: 761px;
  height: 541px;
}
body#home section.feature .feature-inner .feature-block2 .feature-block-txt {
  float: left;
}
body#home section.feature .feature-inner .feature-block2 h3,
body#home section.feature .feature-inner .feature-block2 .feature-block-txt {
  padding: 0 0 0 20px;
  width: 600px;
}
body#home section.feature .feature-inner .feature-block3 h3 {
  margin-top: 100px;
}
@media screen and (min-width: 1000px) and (max-width: 1400px) {
  body#home section.feature .feature-inner {
    background-position: right 60px top 105px, left 480px top 600px, right 100px top 1180px;
  }
  body#home section.feature .feature-inner .feature-block1 .feature-block-ph {
    width: calc(100% - 650px);
    overflow: hidden;
    position: relative;
  }
  body#home section.feature .feature-inner .feature-block1 .feature-block-ph img {
    width: 700px;
    position: absolute;
    right: 0;
    top: 0;
  }
  body#home section.feature .feature-inner .feature-block1 h3,
  body#home section.feature .feature-inner .feature-block1 .feature-block-txt {
    padding: 0 40px 0 30px;
  }
  body#home section.feature .feature-inner .feature-block2 .feature-block-ph {
    width: calc(100% - 630px);
    overflow: hidden;
    position: relative;
  }
  body#home section.feature .feature-inner .feature-block2 .feature-block-ph img {
    width: 749px;
    position: absolute;
    left: 0;
    top: 0;
  }
  body#home section.feature .feature-inner .feature-block3 .feature-block-ph img {
    left: 0;
  }
}
@media screen and (max-width: 999px) {
  body#home section.feature {
    padding: 18.6vw 0 0;
  }
  body#home section.feature h2 {
    margin: 0 auto;
    padding-top: 17.5vw;
    max-width: auto;
    width: calc(100% - 8vw);
    height: 53.3vw;
    line-height: 1.7;
    background: url("/assets/images/home/feature_bg_sp.jpg") no-repeat center;
    background-size: cover;
    font-size: 3.46vw;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
  }
  body#home section.feature h2 span {
    font-size: 5.33vw;
  }
  body#home section.feature h2 span span {
    font-size: 6.66vw;
  }
  body#home section.feature h2 span span.img {
    width: 30.66vw;
    height: 5.866vw;
  }
  body#home section.feature .feature-wrap {
    padding-top: 24.66vw;
    position: relative;
    top: -2.66vw;
    background: url("/assets/images/home/feature_bg1_sp.png") no-repeat, url("/assets/images/home/feature_bg2_sp.png") no-repeat, url("/assets/images/home/feature_bg3_sp.png") no-repeat;
    background-position: right 0 top 0, left 0 top 42vw, right 0 top 267vw;
    background-size: 64.8vw 65.4vw, 74.5vw 102.4vw, 74.6vw 96.4vw;
  }
  body#home section.feature .feature-inner {
    margin: 0;
    max-width: auto;
    width: 100%;
    background: url("/assets/images/home/feature_bg_num1.png") no-repeat, url("/assets/images/home/feature_bg_num2.png") no-repeat, url("/assets/images/home/feature_bg_num3.png") no-repeat;
    background-position: right 4.5vw top 20vw, left 7.4vw top 139.5vw, right 6vw top 250.8vw;
    background-size: 14.6vw auto, 18.6vw auto, 17.3vw auto;
  }
  body#home section.feature .feature-inner h3 {
    margin-bottom: 8vw;
    font-size: 4vw;
  }
  body#home section.feature .feature-inner h3 span {
    margin-left: -5px;
    font-size: 9.33vw;
    letter-spacing: 0px;
  }
  body#home section.feature .feature-inner .feature-block1 {
    margin-bottom: 10vw;
  }
  body#home section.feature .feature-inner .feature-block1 h3 {
    margin: 0 auto 8vw;
    order: 2;
    text-align: right;
  }
  body#home section.feature .feature-inner .feature-block1 h3 span {
    letter-spacing: -2px;
  }
  body#home section.feature .feature-inner .feature-block1 .feature-block-ph {
    margin: 0 auto 7.3vw 0;
    width: 71.466vw;
    height: 46.133vw;
    order: 1;
  }
  body#home section.feature .feature-inner .feature-block1 .feature-block-txt {
    order: 3;
  }
  body#home section.feature .feature-inner .feature-block2 {
    margin-bottom: 24vw;
  }
  body#home section.feature .feature-inner .feature-block2 h3 {
    margin: 0 auto 8vw;
    order: 2;
    text-align: right;
  }
  body#home section.feature .feature-inner .feature-block2 .feature-block-ph {
    margin: 0 0 9.3vw auto;
    width: 65.7vw;
    order: 1;
  }
  body#home section.feature .feature-inner .feature-block2 .feature-block-txt {
    order: 3;
  }
  body#home section.feature .feature-inner .feature-block3 {
    margin-bottom: 0;
    padding-bottom: 25.3vw;
  }
  body#home section.feature .feature-inner .feature-block1,
  body#home section.feature .feature-inner .feature-block2,
  body#home section.feature .feature-inner .feature-block3 {
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }
  body#home section.feature .feature-inner .feature-block1 h3,
  body#home section.feature .feature-inner .feature-block1 .feature-block-ph,
  body#home section.feature .feature-inner .feature-block1 .feature-block-txt,
  body#home section.feature .feature-inner .feature-block2 h3,
  body#home section.feature .feature-inner .feature-block2 .feature-block-ph,
  body#home section.feature .feature-inner .feature-block2 .feature-block-txt,
  body#home section.feature .feature-inner .feature-block3 h3,
  body#home section.feature .feature-inner .feature-block3 .feature-block-ph,
  body#home section.feature .feature-inner .feature-block3 .feature-block-txt {
    float: none;
  }
  body#home section.feature .feature-inner .feature-block1 h3,
  body#home section.feature .feature-inner .feature-block1 .feature-block-txt,
  body#home section.feature .feature-inner .feature-block2 h3,
  body#home section.feature .feature-inner .feature-block2 .feature-block-txt,
  body#home section.feature .feature-inner .feature-block3 h3,
  body#home section.feature .feature-inner .feature-block3 .feature-block-txt {
    padding: 0;
    width: 86.8vw;
  }
  body#home section.feature .feature-inner .feature-block1 .feature-block-txt,
  body#home section.feature .feature-inner .feature-block2 .feature-block-txt,
  body#home section.feature .feature-inner .feature-block3 .feature-block-txt {
    margin: 0 auto;
  }
  body#home section.feature .feature-inner .feature-block1 .feature-block-ph,
  body#home section.feature .feature-inner .feature-block2 .feature-block-ph,
  body#home section.feature .feature-inner .feature-block3 .feature-block-ph {
    height: auto;
  }
}
body#home section.flow {
  margin-top: 125px;
  padding: 120px 0;
  background: url("/assets/images/common/bg_border_pc.png") repeat;
  background-size: 1px 4px;
}
@media screen and (max-width: 999px) {
  body#home section.flow {
    background: url("/assets/images/common/bg_border_sp.png") repeat;
    background-size: 1px 4px;
  }
}
body#home section.flow h2 {
  margin-bottom: 60px;
  text-align: center;
  color: #003981;
  font-size: 36px;
  font-weight: bold;
}
body#home section.flow h2 span {
  margin-bottom: 15px;
  display: block;
  color: #787878;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 20px;
}
@media screen and (max-width: 999px) {
  body#home section.flow h2 {
    margin-bottom: 8.5vw;
    font-size: 5.33vw;
  }
  body#home section.flow h2 span {
    margin-bottom: 1.3vw;
    font-size: 2.66vw;
  }
}
body#home section.flow .flow-inner {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1200px;
}
body#home section.flow .flow-inner .kome {
  margin-top: 20px;
  font-size: 15px;
}
body#home section.flow .flow-inner .flow-tab-btn {
  margin: 0 auto;
  max-width: 1100px;
}
body#home section.flow .flow-inner .flow-tab-btn ul {
  display: flex;
  position: relative;
  top: 1px;
  background: #fff;
}
body#home section.flow .flow-inner .flow-tab-btn li {
  padding: 0 20px;
  border: 1px #dcdcdc solid;
  border-right: none;
  height: 70px;
  flex-grow: 1;
  line-height: 70px;
  background: #f4f4f4;
  color: #787878;
  font-size: 18px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s all;
}
body#home section.flow .flow-inner .flow-tab-btn li.tab4 .ti {
  font-size: 17px;
}
body#home section.flow .flow-inner .flow-tab-btn li:last-child {
  border-right: 1px #dcdcdc solid;
}
body#home section.flow .flow-inner .flow-tab-btn li span {
  font-weight: bold;
}
body#home section.flow .flow-inner .flow-tab-btn li span.num {
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
}
body#home section.flow .flow-inner .flow-tab-btn li span.ti {
  display: none;
}
body#home section.flow .flow-inner .flow-tab-btn li.is-active {
  border-bottom: 1px #fff solid;
  cursor: auto;
}
@media screen and (min-width: 1000px) {
  body#home section.flow .flow-inner .flow-tab-btn li:hover, body#home section.flow .flow-inner .flow-tab-btn li.is-active {
    background: #fff;
  }
  body#home section.flow .flow-inner .flow-tab-btn li:hover span, body#home section.flow .flow-inner .flow-tab-btn li.is-active span {
    display: inline-block;
  }
  body#home section.flow .flow-inner .flow-tab-btn li:hover span.num, body#home section.flow .flow-inner .flow-tab-btn li.is-active span.num {
    margin-right: 20px;
    color: #003981;
    font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  }
  body#home section.flow .flow-inner .flow-tab-btn li:hover span.ti, body#home section.flow .flow-inner .flow-tab-btn li.is-active span.ti {
    color: #1e1e1e;
  }
}
@media screen and (min-width: 1000px) {
  body#home section.flow .flow-inner .flow-tab-btn ul:hover li:hover {
    flex-grow: 2;
  }
  body#home section.flow .flow-inner .flow-tab-btn ul:hover li:hover span {
    display: inline-block;
  }
  body#home section.flow .flow-inner .flow-tab-btn ul:hover li:hover span.num {
    margin-right: 20px;
  }
  body#home section.flow .flow-inner .flow-tab-btn ul:hover li.is-active {
    flex-grow: 1;
    text-align: center;
  }
  body#home section.flow .flow-inner .flow-tab-btn ul:hover li.is-active span {
    margin-right: 0;
    display: block;
  }
  body#home section.flow .flow-inner .flow-tab-btn ul:hover li.is-active span.ti {
    display: none;
  }
  body#home section.flow .flow-inner .flow-tab-btn ul:hover li.is-active:hover span {
    display: inline-block;
  }
  body#home section.flow .flow-inner .flow-tab-btn ul:hover li.is-active:hover span.num {
    margin-right: 20px;
  }
}
body#home section.flow .flow-inner .flow-tab-content {
  padding: 50px 100px;
  border: 1px #dcdcdc solid;
  max-width: 1200px;
  background: #fff;
  box-sizing: border-box;
}
body#home section.flow .flow-inner .flow-tab-content img {
  margin: 0 auto 40px;
  max-width: 700px;
  display: block;
}
body#home section.flow .flow-inner .flow-tab-content .panel {
  display: none;
}
@media screen and (min-width: 1000px) {
  body#home section.flow .flow-inner .flow-tab-content .panel.is-show {
    display: block;
  }
}
@media screen and (max-width: 999px) {
  body#home section.flow {
    margin-top: 21.3vw;
    padding: 14.6vw 0 20vw;
  }
  body#home section.flow .flow-inner {
    padding: 0;
    max-width: 100%;
  }
  body#home section.flow .flow-inner p.kome {
    margin-top: 4vw;
    padding: 0 6.6vw;
    font-size: 3.2vw;
  }
  body#home section.flow .flow-inner .flow-tab-btn {
    max-width: 100%;
  }
  body#home section.flow .flow-inner .flow-tab-btn ul {
    display: flex;
    position: relative;
    top: 1px;
    z-index: 1;
    background: #fff;
  }
  body#home section.flow .flow-inner .flow-tab-btn li {
    padding: 0 2.66vw;
    height: 11.3vw;
    line-height: 11.3vw;
    font-size: 3.46vw;
  }
  body#home section.flow .flow-inner .flow-tab-btn li.tab4 .ti {
    font-size: 3.46vw;
  }
  body#home section.flow .flow-inner .flow-tab-btn li:last-child {
    border-right: none;
  }
  body#home section.flow .flow-inner .flow-tab-btn .slick-current {
    width: 77.4vw;
  }
  body#home section.flow .flow-inner .flow-tab-btn .slick-current li {
    border-bottom: 1px #fff solid;
    background: #fff;
  }
  body#home section.flow .flow-inner .flow-tab-btn .slick-current li span {
    display: inline-block;
  }
  body#home section.flow .flow-inner .flow-tab-btn .slick-current li span.num {
    margin-right: 20px;
    color: #003981;
    font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  }
  body#home section.flow .flow-inner .flow-tab-btn .slick-current li span.ti {
    color: #1e1e1e;
  }
  body#home section.flow .flow-inner .flow-tab-content {
    padding: 8vw 6.6vw 13.3vw;
    border-right: none;
    border-left: none;
    max-width: 100%;
    height: auto;
    overflow-y: visible;
  }
  body#home section.flow .flow-inner .flow-tab-content.scroll {
    height: auto;
  }
  body#home section.flow .flow-inner .flow-tab-content img {
    margin-bottom: 6.6vw;
    width: 100%;
  }
  body#home section.flow .flow-inner .flow-tab-content .flow-tab-content-txt p {
    font-size: 3.2vw;
    text-align: left;
  }
  body#home section.flow .flow-inner .flow-tab-content button.slick-prev,
  body#home section.flow .flow-inner .flow-tab-content button.slick-prev:hover,
  body#home section.flow .flow-inner .flow-tab-content button.slick-prev:focus {
    top: 34.4vw;
  }
  body#home section.flow .flow-inner .flow-tab-content button.slick-next,
  body#home section.flow .flow-inner .flow-tab-content button.slick-next:hover,
  body#home section.flow .flow-inner .flow-tab-content button.slick-next:focus {
    top: 34.4vw;
  }
}
body#home section.team {
  padding: 145px 0 180px;
  background: url("/assets/images/home/team_bg1_pc.png") no-repeat, url("/assets/images/home/team_bg2_pc.png") no-repeat;
  background-position: left calc(50% - 280px) top 160px, right calc(50% - 200px) top 620px;
  background-size: 750px 353px, 679px 277px;
}
body#home section.team h2 {
  margin-bottom: 60px;
  text-align: center;
  color: #003981;
  font-size: 36px;
  font-weight: bold;
}
body#home section.team h2 span {
  margin-bottom: 15px;
  display: block;
  color: #787878;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 20px;
}
@media screen and (max-width: 999px) {
  body#home section.team h2 {
    margin-bottom: 8.5vw;
    font-size: 5.33vw;
  }
  body#home section.team h2 span {
    margin-bottom: 1.3vw;
    font-size: 2.66vw;
  }
}
body#home section.team h2 {
  margin-bottom: 18px;
}
body#home section.team .team-h2-kome {
  margin-bottom: 80px;
  font-size: 15px;
  text-align: center;
}
body#home section.team .team-inner {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1200px;
  display: flex;
}
body#home section.team .team-list {
  width: 640px;
}
body#home section.team .team-list ul {
  position: relative;
  height: 440px;
  background: url("/assets/images/home/team_bg_tri_pc.png") no-repeat;
  background-size: 300px 217px;
  background-position: top 110px left 180px;
}
body#home section.team .team-list li {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: #fff;
  box-sizing: border-box;
  position: absolute;
}
body#home section.team .team-list li h3 {
  border: 2px #003981 dotted;
  position: relative;
  z-index: 2;
  width: 230px;
  height: 230px;
  line-height: 1.7;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #003981;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s all;
}
body#home section.team .team-list li h3 span {
  margin-top: 5px;
  font-size: 14px;
  font-weight: normal;
  display: block;
}
body#home section.team .team-list li h3:hover {
  color: #fff;
  background: #003981;
}
body#home section.team .team-list li.team-list1 {
  top: 0;
  left: 210px;
}
body#home section.team .team-list li.team-list1 h3 {
  padding-top: 105px;
  line-height: 1;
}
body#home section.team .team-list li.team-list2 {
  top: 210px;
  left: 40px;
}
body#home section.team .team-list li.team-list2 h3 {
  padding-top: 88px;
}
body#home section.team .team-list li.team-list3 {
  top: 210px;
  left: 370px;
}
body#home section.team .team-list li.team-list3 h3 {
  padding-top: 70px;
}
body#home section.team .team-list li.is-active {
  border: none;
}
body#home section.team .team-list li.is-active h3 {
  width: 230px;
  height: 230px;
  color: #fff;
  background: #003981;
  cursor: auto;
}
body#home section.team .team-list li.is-active h3:hover {
  opacity: 1;
}
body#home section.team .team-list li.team-list1.is-active::before {
  width: 210px;
  height: 1px;
  border-top: 1px #003981 solid;
  position: absolute;
  content: "";
  z-index: 1;
  top: 120px;
  left: 228px;
}
body#home section.team .team-list li.team-list2.is-active::before {
  width: 466px;
  height: 30px;
  border: 1px #003981 solid;
  border-top: none;
  position: absolute;
  content: "";
  z-index: 1;
  top: 228px;
  left: 110px;
}
body#home section.team .team-list li.team-list2.is-active::after {
  width: 30px;
  height: 180px;
  border: 1px #003981 solid;
  border-bottom: none;
  border-right: none;
  position: absolute;
  content: "";
  z-index: 1;
  top: 50px;
  left: 577px;
}
body#home section.team .team-list li.team-list3.is-active::before {
  width: 21px;
  height: 64px;
  border: 1px #003981 solid;
  border-top: none;
  border-left: none;
  position: absolute;
  content: "";
  z-index: 1;
  top: 50px;
  left: 226px;
}
body#home section.team .team-list li.team-list3.is-active::after {
  width: 30px;
  height: 1px;
  border-top: 1px #003981 solid;
  position: absolute;
  content: "";
  z-index: 1;
  top: 50px;
  left: 247px;
}
body#home section.team .team-txt {
  margin-left: auto;
  padding: 65px 50px;
  position: relative;
  z-index: 2;
  border: 1px #003981 solid;
  width: calc(100% - 640px);
  max-width: 600px;
  box-sizing: border-box;
  background: #fff;
}
body#home section.team .team-txt h4 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px #dcdcdc solid;
  color: #003981;
  font-size: 20px;
  font-weight: bold;
}
body#home section.team .team-txt .team-txt-content {
  display: none;
}
body#home section.team .team-txt .team-txt-content.is-show {
  display: block;
}
@media screen and (max-width: 999px) {
  body#home section.team {
    padding: 24vw 0 14.6vw;
    background: transparent;
  }
  body#home section.team h2 {
    margin-bottom: 60px;
    text-align: center;
    color: #003981;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 2vw !important;
    line-height: 1.5;
  }
  body#home section.team h2 span {
    margin-bottom: 15px;
    display: block;
    color: #787878;
    font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
    font-size: 20px;
  }
}
@media screen and (max-width: 999px) and (max-width: 999px) {
  body#home section.team h2 {
    margin-bottom: 8.5vw;
    font-size: 5.33vw;
  }
  body#home section.team h2 span {
    margin-bottom: 1.3vw;
    font-size: 2.66vw;
  }
}
@media screen and (max-width: 999px) {
  body#home section.team .team-h2-kome {
    margin-bottom: 8vw;
    font-size: 2.66vw;
  }
  body#home section.team .team-inner {
    margin: 0;
    padding: 0;
    max-width: 100%;
    display: block;
  }
  body#home section.team .team-list {
    margin: 0 0 7.2vw;
    width: 100%;
    height: 75.66vw;
    box-sizing: border-box;
  }
  body#home section.team .team-list ul {
    height: 75.66vw;
    background: url("/assets/images/home/team_bg_tri_sp.png") no-repeat;
    background-size: 46.933vw 34vw;
    background-position: top 22.5vw left 26vw;
  }
  body#home section.team .team-list li {
    width: 39.33vw;
    height: 39.33vw;
  }
  body#home section.team .team-list li h3 {
    width: 39.33vw;
    height: 39.33vw;
    font-size: 3.46vw;
  }
  body#home section.team .team-list li h3 span {
    margin-top: 0;
    font-size: 2.6vw;
  }
  body#home section.team .team-list li.is-active h3 {
    width: 39.33vw;
    height: 39.33vw;
  }
  body#home section.team .team-list li.team-list1 {
    top: 0;
    left: 30.13vw;
  }
  body#home section.team .team-list li.team-list1 h3 {
    padding-top: 17.8vw;
  }
  body#home section.team .team-list li.team-list2 {
    top: 35.46vw;
    left: 5.33vw;
  }
  body#home section.team .team-list li.team-list2 h3 {
    padding-top: 15.2vw;
  }
  body#home section.team .team-list li.team-list3 {
    top: 35.46vw;
    left: 55.34vw;
  }
  body#home section.team .team-list li.team-list3 h3 {
    padding-top: 13.7vw;
    line-height: 1.3;
  }
  body#home section.team .team-list li.team-list3 h3 span {
    margin-top: 1vw;
  }
  body#home section.team .team-list li.team-list1.is-active::before {
    width: 0;
    height: 0;
  }
  body#home section.team .team-list li.team-list2.is-active::before, body#home section.team .team-list li.team-list2.is-active::after,
  body#home section.team .team-list li.team-list3.is-active::before,
  body#home section.team .team-list li.team-list3.is-active::after {
    content: none;
  }
  body#home section.team .team-txt {
    margin: 0 auto;
    padding: 10.6vw 8vw;
    width: 86.8vw;
    max-width: auto;
    box-sizing: border-box;
  }
  body#home section.team .team-txt h4 {
    margin-bottom: 3.3vw;
    padding-bottom: 3.3vw;
    font-size: 4vw;
  }
  body#home section.team .team-txt .team-txt-content {
    display: none;
  }
  body#home section.team .team-txt .team-txt-content.is-show {
    display: block;
  }
  body#home section.team .team-txt .team-txt-content.is-show {
    position: relative;
  }
  body#home section.team .team-txt .team-txt-content.is-show::after {
    position: absolute;
    width: 1px;
    content: "";
    background: #003981;
  }
  body#home section.team .team-txt .team-txt1.is-show::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 40vw;
    top: -52vw;
    left: calc(50% - 5px);
    background: #fff;
    z-index: -1;
  }
  body#home section.team .team-txt .team-txt1.is-show::after {
    height: 43.9vw;
    top: -54.5vw;
    left: 50%;
  }
  body#home section.team .team-txt .team-txt2.is-show::after {
    height: 8.4vw;
    top: -19vw;
    left: 10vw;
  }
  body#home section.team .team-txt .team-txt3.is-show::after {
    height: 8.4vw;
    top: -19vw;
    left: 60vw;
  }
}
body#home .block-faq-ph {
  margin-bottom: 110px;
  width: 100%;
  height: 380px;
  background: url("/assets/images/home/faq_bg_pc.jpg") no-repeat;
  background-size: cover;
}
@media screen and (max-width: 999px) {
  body#home .block-faq-ph {
    margin-bottom: 0;
    height: 58.66vw;
    background: url("/assets/images/home/faq_bg_sp.jpg") no-repeat;
    background-size: cover;
  }
}
body#home section.faq {
  padding: 110px 0 150px;
}
body#home section.faq h2 {
  margin-bottom: 60px;
  text-align: center;
  color: #003981;
  font-size: 36px;
  font-weight: bold;
}
body#home section.faq h2 span {
  margin-bottom: 15px;
  display: block;
  color: #787878;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 20px;
}
@media screen and (max-width: 999px) {
  body#home section.faq h2 {
    margin-bottom: 8.5vw;
    font-size: 5.33vw;
  }
  body#home section.faq h2 span {
    margin-bottom: 1.3vw;
    font-size: 2.66vw;
  }
}
body#home section.faq .faq-inner {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1000px;
}
body#home section.faq .faq-inner dl {
  margin-bottom: 10px;
  border: 1px #dcdcdc solid;
  width: 100%;
  background: #f4f4f4;
  border-radius: 6px;
  box-sizing: border-box;
}
body#home section.faq .faq-inner dl dt {
  padding: 22px 100px 22px 65px;
  font-size: 18px;
  position: relative;
  cursor: pointer;
  transition: 0.3s all;
}
body#home section.faq .faq-inner dl dt:hover {
  opacity: 0.7;
}
body#home section.faq .faq-inner dl dt h3 {
  color: #1e1e1e;
  font-weight: bold;
}
body#home section.faq .faq-inner dl dt::before {
  position: absolute;
  content: "Q. ";
  font-size: 26px;
  font-weight: bold;
  color: #003981;
  top: 16px;
  left: 20px;
}
body#home section.faq .faq-inner dl dt::after {
  position: absolute;
  content: "+";
  font-size: 30px;
  color: #003981;
  top: 14px;
  right: 22px;
}
body#home section.faq .faq-inner dl dt.is-active::after {
  content: "−";
  right: 18px;
  top: 16px;
}
body#home section.faq .faq-inner dl dd {
  padding: 0 100px 22px 65px;
  display: none;
}
@media screen and (max-width: 999px) {
  body#home section.faq {
    padding: 11.33vw 0 17.33vw;
  }
  body#home section.faq .faq-inner {
    margin: 0 auto;
    padding: 0 4vw;
    max-width: auto;
  }
  body#home section.faq .faq-inner dl {
    margin-bottom: 2.66vw;
    border-radius: 3px;
  }
  body#home section.faq .faq-inner dl dt {
    padding: 5vw 12vw 5vw 10.66vw;
    line-height: 1.69;
    font-size: 3.46vw;
  }
  body#home section.faq .faq-inner dl dt::before {
    font-size: 4vw;
    top: 4vw;
    left: 4vw;
  }
  body#home section.faq .faq-inner dl dt::after {
    font-size: 4vw;
    top: 4vw;
    right: 4vw;
  }
  body#home section.faq .faq-inner dl dt.is-active::after {
    top: 4.4vw;
    right: 3.6vw;
  }
  body#home section.faq .faq-inner dl dd {
    padding: 0 12vw 5.86vw 10.66vw;
  }
}
body#home section.seminar {
  padding: 95px 0 115px;
  background: url("/assets/images/common/bg_border_pc.png") repeat;
  background-size: 1px 4px;
}
@media screen and (max-width: 999px) {
  body#home section.seminar {
    background: url("/assets/images/common/bg_border_sp.png") repeat;
    background-size: 1px 4px;
  }
}
body#home section.seminar h2 {
  margin-bottom: 60px;
  text-align: center;
  color: #003981;
  font-size: 36px;
  font-weight: bold;
}
body#home section.seminar h2 span {
  margin-bottom: 15px;
  display: block;
  color: #787878;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 20px;
}
@media screen and (max-width: 999px) {
  body#home section.seminar h2 {
    margin-bottom: 8.5vw;
    font-size: 5.33vw;
  }
  body#home section.seminar h2 span {
    margin-bottom: 1.3vw;
    font-size: 2.66vw;
  }
}
body#home section.seminar .seminar-inner {
  margin: 0 auto;
  max-width: 1200px;
}
body#home section.seminar .seminar-content {
  padding: 5px 5px 5px 15px;
  display: flex;
  width: 100%;
}
body#home section.seminar .seminar-content li {
  width: calc(33.3333333333% - 40px);
  display: flex;
  transition: 0.3s all;
}
body#home section.seminar .seminar-content li:hover {
  opacity: 0.7;
}
body#home section.seminar .seminar-content li .slide-li {
  margin: 5px 15px 5px 5px;
  background: #fff;
  box-shadow: 0 0 8px #c5c5c5;
  box-sizing: border-box;
}
body#home section.seminar .seminar-content li .slide-li img {
  width: 100%;
}
body#home section.seminar .seminar-content li .slide-txt {
  padding: 30px 35px;
}
body#home section.seminar .seminar-content li .slide-txt h3 {
  margin-bottom: 30px;
  line-height: 1.8;
  color: #003981;
  font-size: 20px;
  font-weight: bold;
}
@media screen and (min-width: 1000px) {
  body#home section.seminar .seminar-content li .slide-li {
    display: flex;
  }
  body#home section.seminar .seminar-content li a {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  body#home section.seminar .seminar-content li a .slide-ph img {
    width: 100%;
  }
  body#home section.seminar .seminar-content li a .slide-txt {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1270px) {
  body#home section.seminar .seminar-content button.slick-prev {
    left: 0;
  }
  body#home section.seminar .seminar-content button.slick-next {
    right: 0;
  }
}
@media screen and (max-width: 999px) {
  body#home section.seminar .seminar-content {
    padding: 0;
    display: block;
  }
  body#home section.seminar .seminar-content li {
    width: 100%;
  }
  body#home section.seminar .seminar-content li .slide-li {
    margin: 0 0 4vw;
  }
  body#home section.seminar .seminar-content li .slide-li a {
    display: flex;
    flex-wrap: nowrap;
  }
  body#home section.seminar .seminar-content li .slide-ph {
    width: 40vw;
    height: 40vw;
  }
  body#home section.seminar .seminar-content li .slide-ph img {
    width: 100%;
  }
  body#home section.seminar .seminar-content li .slide-txt {
    padding: 5.5vw 5.6vw 5vw;
    width: calc(100% - 40vw);
    box-sizing: border-box;
  }
  body#home section.seminar .seminar-content li .slide-txt h3 {
    margin-bottom: 3.2vw;
    font-size: 3.2vw;
  }
  body#home section.seminar .seminar-content li .slide-txt p {
    font-size: 2.66vw;
  }
}
body#home section.seminar .seminar-content li .tag span {
  margin: 0 5px 5px 0;
  padding: 3px 10px;
  line-height: 1;
  font-size: 16px;
  display: inline-block;
  font-weight: bold;
}
@media all and (-ms-high-contrast: none) {
  body#home section.seminar .seminar-content li .tag span {
    padding: 5px 10px 0;
  }
}
body#home section.seminar .seminar-content li .tag span.online {
  border: 1px #003981 solid;
  color: #003981;
}
body#home section.seminar .seminar-content li .tag span.before {
  color: #787878;
  background: #ebebeb;
}
body#home section.seminar .seminar-content li .tag span.accepting {
  color: #fff;
  background: #003981;
}
body#home section.seminar .seminar-content li .tag span.closed {
  border: 1px #dcdcdc solid;
  color: #969696;
}
body#home section.seminar .seminar-content li .tag-top {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}
body#home section.seminar .seminar-content li .tag-top span {
  margin-bottom: 0;
}
body#home section.seminar .seminar-content li .slide-txt {
  font-weight: bold;
}
body#home section.seminar .seminar-content li .slide-txt h3 {
  margin: 10px 0 30px;
  text-decoration: underline;
}
body#home section.seminar .seminar-content li .slide-txt span.date {
  margin-top: auto;
  color: #969696;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 14px;
}
body#home section.seminar .seminar-content li.closed h3 {
  color: #969696;
}
body#home section.seminar .seminar-content li.closed .slide-txt span.online {
  border: 1px #dcdcdc solid;
  color: #969696;
}
body#home section.seminar .seminar-btn {
  width: 350px;
  height: 70px;
  margin: 60px auto 0 auto;
}
body#home section.seminar .seminar-btn a {
  line-height: 70px;
  font-size: 22px;
  padding-right: 0;
  font-size: 22px;
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 99px;
  box-sizing: border-box;
  font-weight: bold;
  background: #003981;
  color: #fff;
  text-align: right;
  position: relative;
  text-align: center;
}
body#home section.seminar .seminar-btn a:hover {
  opacity: 0.85;
}
body#home section.seminar .seminar-btn a::before {
  width: 20px;
  height: 18px;
  content: "";
  background: url("/assets/images/common/ic_contact.png");
  background-size: 20px 18px;
  position: absolute;
  top: 13px;
  left: 20px;
}
@media screen and (max-width: 999px) {
  body#home section.seminar .seminar-btn a {
    padding-right: 29.3vw;
    font-size: 3.73vw;
  }
}
body#home section.seminar .seminar-btn a::before {
  content: none;
}
@media screen and (max-width: 999px) {
  body#home section.seminar {
    padding: 17.33vw 0 10.93vw;
  }
  body#home section.seminar h2 {
    margin-bottom: 60px;
    text-align: center;
    color: #003981;
    font-size: 36px;
    font-weight: bold;
  }
  body#home section.seminar h2 span {
    margin-bottom: 15px;
    display: block;
    color: #787878;
    font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
    font-size: 20px;
  }
}
@media screen and (max-width: 999px) and (max-width: 999px) {
  body#home section.seminar h2 {
    margin-bottom: 8.5vw;
    font-size: 5.33vw;
  }
  body#home section.seminar h2 span {
    margin-bottom: 1.3vw;
    font-size: 2.66vw;
  }
}
@media screen and (max-width: 999px) {
  body#home section.seminar .seminar-inner {
    margin: 0 auto;
    width: 86.66vw;
    max-width: auto;
  }
  body#home section.seminar .seminar-content {
    padding: 5px 5px 5px 15px;
    display: flex;
    width: 100%;
  }
  body#home section.seminar .seminar-content li {
    width: calc(33.3333333333% - 40px);
    display: flex;
    transition: 0.3s all;
  }
  body#home section.seminar .seminar-content li:hover {
    opacity: 0.7;
  }
  body#home section.seminar .seminar-content li .slide-li {
    margin: 5px 15px 5px 5px;
    background: #fff;
    box-shadow: 0 0 8px #c5c5c5;
    box-sizing: border-box;
  }
  body#home section.seminar .seminar-content li .slide-li img {
    width: 100%;
  }
  body#home section.seminar .seminar-content li .slide-txt {
    padding: 30px 35px;
  }
  body#home section.seminar .seminar-content li .slide-txt h3 {
    margin-bottom: 30px;
    line-height: 1.8;
    color: #003981;
    font-size: 20px;
    font-weight: bold;
  }
}
@media screen and (max-width: 999px) and (min-width: 1000px) {
  body#home section.seminar .seminar-content li .slide-li {
    display: flex;
  }
  body#home section.seminar .seminar-content li a {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  body#home section.seminar .seminar-content li a .slide-ph img {
    width: 100%;
  }
  body#home section.seminar .seminar-content li a .slide-txt {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
@media screen and (max-width: 999px) and (min-width: 1000px) and (max-width: 1270px) {
  body#home section.seminar .seminar-content button.slick-prev {
    left: 0;
  }
  body#home section.seminar .seminar-content button.slick-next {
    right: 0;
  }
}
@media screen and (max-width: 999px) and (max-width: 999px) {
  body#home section.seminar .seminar-content {
    padding: 0;
    display: block;
  }
  body#home section.seminar .seminar-content li {
    width: 100%;
  }
  body#home section.seminar .seminar-content li .slide-li {
    margin: 0 0 4vw;
  }
  body#home section.seminar .seminar-content li .slide-li a {
    display: flex;
    flex-wrap: nowrap;
  }
  body#home section.seminar .seminar-content li .slide-ph {
    width: 40vw;
    height: 40vw;
  }
  body#home section.seminar .seminar-content li .slide-ph img {
    width: 100%;
  }
  body#home section.seminar .seminar-content li .slide-txt {
    padding: 5.5vw 5.6vw 5vw;
    width: calc(100% - 40vw);
    box-sizing: border-box;
  }
  body#home section.seminar .seminar-content li .slide-txt h3 {
    margin-bottom: 3.2vw;
    font-size: 3.2vw;
  }
  body#home section.seminar .seminar-content li .slide-txt p {
    font-size: 2.66vw;
  }
}
@media screen and (max-width: 999px) {
  body#home section.seminar .seminar-content li .slide-li a {
    flex-wrap: wrap !important;
  }
  body#home section.seminar .seminar-content li .tag span {
    margin-right: 1.73vw;
    padding: 0 1.33vw;
    height: 4.8vw;
    line-height: 4.8vw;
    font-size: 2.66vw;
    font-weight: bold;
    text-align: center;
  }
  body#home section.seminar .seminar-content li .slide-ph {
    width: 100% !important;
    height: auto !important;
  }
  body#home section.seminar .seminar-content li .slide-ph img {
    height: 100%;
  }
  body#home section.seminar .seminar-content li .tag-top {
    padding: 1.86vw 2.66vw;
  }
  body#home section.seminar .seminar-content li .tag-btm {
    width: 100%;
  }
  body#home section.seminar .seminar-content li .slide-txt {
    padding: 1.86vw 4vw 3.06vw !important;
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  body#home section.seminar .seminar-content li .slide-txt h3 {
    margin: 0 !important;
    flex-grow: 1;
    font-size: 3.46vw;
  }
  body#home section.seminar .seminar-content li .slide-txt span.date {
    margin-top: 2.66vw;
    width: 100%;
    font-size: 2.66vw;
    display: block;
  }
  body#home section.seminar .seminar-content li.closed h3 {
    color: #969696;
  }
  body#home section.seminar .seminar-btn {
    width: 100%;
    height: 12.5vw;
    margin: 6vw auto 0 auto;
  }
  body#home section.seminar .seminar-btn a {
    line-height: 12.5vw;
    padding-right: 0;
  }
}

body#list section.dl {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1200px;
}
body#list section.dl h2 {
  margin-bottom: 60px;
  text-align: center;
  color: #003981;
  font-size: 36px;
  font-weight: bold;
}
body#list section.dl h2 span {
  margin-bottom: 15px;
  display: block;
  color: #787878;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 20px;
}
@media screen and (max-width: 999px) {
  body#list section.dl h2 {
    margin-bottom: 8.5vw;
    font-size: 5.33vw;
  }
  body#list section.dl h2 span {
    margin-bottom: 1.3vw;
    font-size: 2.66vw;
  }
}
body#list section.dl h2 {
  margin-bottom: 110px;
  padding-top: 150px;
  font-size: 30px;
}
body#list section.dl h2 span {
  margin-bottom: 10px;
  font-size: 14px;
}
body#list section.dl h2 div {
  margin-top: 10px;
  font-size: 14px;
}
body#list .dl-content {
  margin-bottom: 40px;
}
body#list .dl-content h3 {
  margin-bottom: 60px;
  font-size: 22px;
  font-weight: bold;
  position: relative;
}
body#list .dl-content h3::after {
  position: absolute;
  content: "";
  width: 27px;
  height: 1px;
  background: #003981;
  bottom: -15px;
  left: 0;
}
body#list .dl-content ul.dl-content-li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
body#list .dl-content ul.dl-content-li li {
  margin: 0 30px 60px 0;
  width: calc((100% - 60px) / 3);
}
body#list .dl-content ul.dl-content-li li:nth-child(3n) {
  margin-right: 0;
}
body#list .dl-content ul.dl-content-li li .dl-content-li-img {
  width: 100%;
}
body#list .dl-content ul.dl-content-li li .dl-content-li-img img {
  width: 100%;
}
body#list .dl-content ul.dl-content-li li h4 {
  margin: 25px 0 22px;
  line-height: 1.5;
  font-size: 18px;
  font-weight: bold;
  color: #003981;
}
body#list .dl-content ul.dl-content-li li p {
  margin-bottom: 30px;
}
body#list .dl-content ul.dl-content-li li a {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 99px;
  box-sizing: border-box;
  font-weight: bold;
  background: #fff;
  color: #003981;
  padding-left: 34px;
  width: 240px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  position: relative;
  background: #f0f0f0;
  border-radius: 0;
  text-align: left;
}
body#list .dl-content ul.dl-content-li li a:hover {
  opacity: 0.8;
}
body#list .dl-content ul.dl-content-li li a::after {
  position: absolute;
  content: "";
  width: 21px;
  height: 19px;
  background-image: url("/assets/images/common/ic_dl_b.png");
  background-repeat: no-repeat;
  background-size: 21px 19px;
  top: 20px;
  right: 25px;
}
body#list .dl-content ul.dl-content-li li a:hover {
  background: #003981;
  color: #fff;
  opacity: 1;
}
body#list .dl-content ul.dl-content-li li a:hover::after {
  background-image: url("/assets/images/common/ic_dl_w.png");
}
@media screen and (max-width: 999px) {
  body#list section.dl {
    margin: 0 auto;
    padding: 0 4vw;
    max-width: 100%;
    box-sizing: border-box;
  }
  body#list section.dl h2 {
    margin-bottom: 12vw;
    padding-top: 23.2vw;
    font-size: 4.26vw;
  }
  body#list section.dl h2 span {
    margin-bottom: 10px;
    font-size: 2.4vw;
  }
  body#list .dl-content {
    margin-bottom: 18vw;
  }
  body#list .dl-content h3 {
    margin-bottom: 8.8vw;
    font-size: 3.73vw;
    text-align: center;
  }
  body#list .dl-content h3::after {
    position: absolute;
    content: "";
    width: 3.6vw;
    bottom: -12px;
    left: calc(50% - 1.8vw);
  }
  body#list .dl-content ul.dl-content-li {
    width: 100%;
    display: block;
  }
  body#list .dl-content ul.dl-content-li li {
    margin: 0 0 10.66vw;
    width: 100%;
  }
  body#list .dl-content ul.dl-content-li li:last-child {
    margin-bottom: 0;
  }
  body#list .dl-content ul.dl-content-li li h4 {
    margin: 4.66vw 0 4.3vw;
    font-size: 3.46vw;
    text-align: center;
  }
  body#list .dl-content ul.dl-content-li li p {
    margin: 0 auto 5vw;
    width: 86.67vw;
  }
  body#list .dl-content ul.dl-content-li li a {
    display: block;
    width: 100%;
    text-align: center;
    border-radius: 99px;
    box-sizing: border-box;
    font-weight: bold;
    background: #003981;
    color: #fff;
    margin: 0 auto;
    padding-left: 8vw;
    width: 44.66vw;
    height: 10.93vw;
    line-height: 10.93vw;
    font-size: 3.2vw;
    color: #fff;
    text-align: left;
  }
  body#list .dl-content ul.dl-content-li li a:hover {
    opacity: 0.85;
  }
  body#list .dl-content ul.dl-content-li li a::after {
    position: absolute;
    content: "";
    width: 3.77vw;
    height: 3.46vw;
    background-image: url("/assets/images/common/ic_dl_w.png");
    background-repeat: no-repeat;
    background-size: 3.77vw 3.46vw;
    top: 3.6vw;
    right: 3.6vw;
  }
  body#list .dl-content ul.dl-content-li li a:hover {
    background: #f0f0f0;
    color: #000000;
  }
  body#list .dl-content ul.dl-content-li li a:hover::after {
    background-image: url("/assets/images/common/ic_dl_b.png");
  }
}

body.voice .mv {
  width: 100%;
}
body.voice .mv img {
  width: 100%;
}
body.voice section h2 {
  margin-bottom: 30px;
  line-height: 1.8;
  font-size: 30px;
  font-weight: bold;
  color: #003981;
}
body.voice section h2 span {
  font-size: 115%;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
}
body.voice section h3 {
  margin-bottom: 10px;
  line-height: 1.9;
  font-size: 22px;
  font-weight: bold;
}
body.voice section p {
  margin-bottom: 10px;
}
body.voice section p.bold {
  font-weight: bold;
}
body.voice section p:last-child {
  margin-bottom: 0;
}
body.voice section .sec-inner {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1200px;
}
body.voice section .sec-wrap {
  margin-bottom: 60px;
  width: 100%;
}
body.voice section .sec-wrap:last-child {
  margin-bottom: 100px;
}
body.voice section.sec1 {
  background: url("/assets/images/voice/bg1_pc.png") top 0 right 0/827px 776px no-repeat;
}
body.voice section.sec1 .sec1-logo {
  margin: 0 auto 150px;
  padding-top: 60px;
  width: 100%;
}
body.voice section.sec1 .sec1-logo img {
  width: 100%;
}
body.voice section.sec1 h2 {
  margin-bottom: 15px;
}
body.voice section.sec1 p.sec1-company-name {
  margin-bottom: 50px;
  font-weight: bold;
}
body.voice section.sec1 .sec1-description {
  margin-bottom: 60px;
}
body.voice section.sec1 .sec1-img {
  margin: 0 auto 200px;
  max-width: 1000px;
}
body.voice section.sec1 .sec1-img img {
  width: 100%;
}
body.voice section.sec1 .sec1-img p {
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.9;
}
body.voice section.sec1 .sec1-img p span {
  display: block;
}
body.voice .wide-ph {
  margin-bottom: 160px;
  width: 100%;
  background: #103377;
}
body.voice .wide-ph .wide-ph-content {
  margin: 0 auto;
  max-width: 1200px;
  background: #103377;
}
body.voice .wide-ph .wide-ph-content img {
  width: 100%;
  vertical-align: bottom;
}
body.voice section.sec2 {
  background: url("/assets/images/voice/bg2_pc.png") no-repeat top 364px right 0/874px 776px;
}
body.voice section.sec2 .sec-ph-l {
  margin: 0 auto 0 0;
  float: left;
  width: calc(100% - 560px - 70px);
  height: 700px;
}
body.voice section.sec2 .sec-ph-l img {
  width: 100%;
}
body.voice section.sec2 .sec-ph-l-txt {
  float: right;
  max-width: 560px;
}
body.voice section.sec3 {
  padding-top: 96px;
  background: url("/assets/images/voice/bg3_pc.png") no-repeat 0 0/840px 1183px;
}
body.voice section.sec3 .sec-ph-r {
  margin: 0 0 0 auto;
  float: right;
  width: calc(100% - 560px - 70px);
  height: 670px;
}
body.voice section.sec3 .sec-ph-r img {
  width: 100%;
}
body.voice section.sec3 .sec-ph-r-txt {
  float: left;
  max-width: 560px;
}
body.voice section.sec3 p.sec2-notice {
  margin-bottom: 140px;
  font-size: 14px;
  text-align: center;
}
body.voice section.sec3 .sec-wrap-img {
  display: block;
  width: 100%;
  max-width: 800px;
}
body.voice section.sec3 .sec-wrap-img img {
  width: 100%;
  height: auto;
}
body.voice section.recommend {
  padding: 120px 0 150px;
  background: #f4f4f4;
}
body.voice section.recommend h2 {
  margin-bottom: 60px;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: #000000;
}
body.voice section.recommend ul {
  width: 100%;
  display: flex;
}
body.voice section.recommend ul li {
  margin-right: 30px;
  width: calc((100% - 60px) / 3);
}
body.voice section.recommend ul li:nth-child(3) {
  margin-right: 0;
}
body.voice section.recommend ul li a {
  transition: 0.3s all;
  display: flex;
  flex-wrap: wrap;
}
body.voice section.recommend ul li a:hover {
  opacity: 0.7;
}
body.voice section.recommend ul li .recommend-ph {
  width: 100%;
  order: 1;
}
body.voice section.recommend ul li .recommend-ph img {
  width: 100%;
}
body.voice section.recommend ul li p {
  margin: 10px 0;
  order: 2;
}
body.voice section.recommend ul li .recommend-logo {
  order: 3;
}
@media screen and (max-width: 999px) {
  body.voice .mv {
    width: 100%;
  }
  body.voice .mv img {
    width: 100%;
  }
  body.voice section h2 {
    margin-bottom: 2.66vw;
    font-size: 4.66vw;
  }
  body.voice section h3 {
    margin-bottom: 5vw;
    font-size: 3.46vw;
  }
  body.voice section p {
    margin-bottom: 5vw;
  }
  body.voice section p:last-child {
    margin-bottom: 0;
  }
  body.voice section .sec-inner {
    margin: 0 auto;
    padding: 0 6.6vw;
    max-width: 100%;
    box-sizing: border-box;
  }
  body.voice section .sec-wrap {
    margin-bottom: 13.3vw;
  }
  body.voice section .sec-wrap:last-child {
    margin-bottom: 13.3vw;
  }
  body.voice section .sec-wrap-img {
    display: block;
    width: 100%;
    max-width: 800px;
    overflow: hidden; /* 必要に応じてコンテンツのはみ出しを防ぐ */
  }
  body.voice section .sec-wrap-img img {
    width: 100%;
    height: auto; /* アスペクト比を維持 */
    max-width: 100%; /* 画像が画面幅を超えない */
  }
  body.voice section.sec1 {
    background: url("/assets/images/voice/bg1_sp.png") top 0 right 0/75.4vw 103.46vw no-repeat;
  }
  body.voice section.sec1 .sec1-logo {
    margin: 0 auto 9.33vw;
    padding-top: 8vw;
  }
  body.voice section.sec1 h2 {
    margin-bottom: 5vw;
  }
  body.voice section.sec1 p.sec1-company-name {
    margin-bottom: 5vw;
  }
  body.voice section.sec1 .sec1-description {
    margin-bottom: 10.6vw;
  }
  body.voice section.sec1 .sec1-img {
    margin: 0 auto 14.66vw;
    max-width: 100%;
  }
  body.voice section.sec1 .sec1-img p {
    margin-top: 5.33vw;
    font-size: 3.2vw;
  }
  body.voice section.sec1 .sec1-img p span {
    margin-top: 3vw;
    display: block;
  }
  body.voice .wide-ph {
    margin-bottom: 13.3vw;
    background: transparent;
  }
  body.voice .wide-ph .wide-ph-content {
    max-width: 100%;
    background: transparent;
  }
  body.voice section.sec2 {
    background: url("/assets/images/voice/bg2_sp.png") no-repeat top 93.33vw left 0/73.86vw 166.66vw;
  }
  body.voice section.sec2 .sec-ph-l-txt {
    float: none;
    width: 100%;
    position: relative;
  }
  body.voice section.sec2 .sec2-ph {
    margin: 0 auto 13.33vw;
    width: 73.33vw;
    height: 91.73vw;
    position: relative;
  }
  body.voice section.sec2 .sec2-ph img {
    width: 100%;
  }
  body.voice section.sec3 {
    padding-top: 0;
    background: url("/assets/images/voice/bg3_sp.png") no-repeat top 0 right 0/69.73vw 166.66vw;
  }
  body.voice section.sec3 .sec-ph-r {
    margin: 0 0 16vw auto;
    float: none;
    width: 80vw;
    height: 89.3vw;
    right: -6.6vw;
    position: relative;
  }
  body.voice section.sec3 .sec-ph-r-txt {
    float: none;
    width: 100%;
  }
  body.voice section.sec3 p.sec2-notice {
    margin-bottom: 13.33vw;
    font-size: 3.2vw;
  }
  body.voice section.recommend {
    padding: 18vw 0;
  }
  body.voice section.recommend h2 {
    margin-bottom: 9.33vw;
    font-size: 3.73vw;
  }
  body.voice section.recommend ul {
    display: block;
  }
  body.voice section.recommend ul li {
    margin: 0 0 8vw;
    width: 100%;
  }
  body.voice section.recommend ul li:last-child {
    margin-bottom: 0;
  }
  body.voice section.recommend ul li a {
    width: 100%;
    position: relative;
    display: block;
  }
  body.voice section.recommend ul li .recommend-ph {
    margin: 0 auto 0 0;
    width: 43.3vw;
    height: 28.53vw;
  }
  body.voice section.recommend ul li .recommend-ph img {
    width: 100%;
  }
  body.voice section.recommend ul li p {
    margin: 0;
    width: calc(100% - 43.3vw - 4vw);
    position: absolute;
    top: 0;
    right: 0;
  }
  body.voice section.recommend ul li .recommend-logo {
    margin-top: 1.3vw;
    margin-right: calc(100% - 43.3vw);
    width: 43.3vw;
  }
  body.voice section.recommend ul li .recommend-logo img {
    width: 100% !important;
  }
}

body#seminar section.seminar {
  padding: 95px 0 115px;
  background: url("/assets/images/common/bg_border_pc.png") repeat;
  background-size: 1px 4px;
}
@media screen and (max-width: 999px) {
  body#seminar section.seminar {
    background: url("/assets/images/common/bg_border_sp.png") repeat;
    background-size: 1px 4px;
  }
}
body#seminar section.seminar h2 {
  margin-bottom: 60px;
  text-align: center;
  color: #003981;
  font-size: 36px;
  font-weight: bold;
}
body#seminar section.seminar h2 span {
  margin-bottom: 15px;
  display: block;
  color: #787878;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 20px;
}
@media screen and (max-width: 999px) {
  body#seminar section.seminar h2 {
    margin-bottom: 8.5vw;
    font-size: 5.33vw;
  }
  body#seminar section.seminar h2 span {
    margin-bottom: 1.3vw;
    font-size: 2.66vw;
  }
}
body#seminar section.seminar .seminar-inner {
  margin: 0 auto;
  max-width: 1200px;
}
body#seminar section.seminar .seminar-content {
  padding: 5px 5px 5px 15px;
  display: flex;
  width: 100%;
}
body#seminar section.seminar .seminar-content li {
  width: calc(33.3333333333% - 40px);
  display: flex;
  transition: 0.3s all;
}
body#seminar section.seminar .seminar-content li:hover {
  opacity: 0.7;
}
body#seminar section.seminar .seminar-content li .slide-li {
  margin: 5px 15px 5px 5px;
  background: #fff;
  box-shadow: 0 0 8px #c5c5c5;
  box-sizing: border-box;
}
body#seminar section.seminar .seminar-content li .slide-li img {
  width: 100%;
}
body#seminar section.seminar .seminar-content li .slide-txt {
  padding: 30px 35px;
}
body#seminar section.seminar .seminar-content li .slide-txt h3 {
  margin-bottom: 30px;
  line-height: 1.8;
  color: #003981;
  font-size: 20px;
  font-weight: bold;
}
@media screen and (min-width: 1000px) {
  body#seminar section.seminar .seminar-content li .slide-li {
    display: flex;
  }
  body#seminar section.seminar .seminar-content li a {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  body#seminar section.seminar .seminar-content li a .slide-ph img {
    width: 100%;
  }
  body#seminar section.seminar .seminar-content li a .slide-txt {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1270px) {
  body#seminar section.seminar .seminar-content button.slick-prev {
    left: 0;
  }
  body#seminar section.seminar .seminar-content button.slick-next {
    right: 0;
  }
}
@media screen and (max-width: 999px) {
  body#seminar section.seminar .seminar-content {
    padding: 0;
    display: block;
  }
  body#seminar section.seminar .seminar-content li {
    width: 100%;
  }
  body#seminar section.seminar .seminar-content li .slide-li {
    margin: 0 0 4vw;
  }
  body#seminar section.seminar .seminar-content li .slide-li a {
    display: flex;
    flex-wrap: nowrap;
  }
  body#seminar section.seminar .seminar-content li .slide-ph {
    width: 40vw;
    height: 40vw;
  }
  body#seminar section.seminar .seminar-content li .slide-ph img {
    width: 100%;
  }
  body#seminar section.seminar .seminar-content li .slide-txt {
    padding: 5.5vw 5.6vw 5vw;
    width: calc(100% - 40vw);
    box-sizing: border-box;
  }
  body#seminar section.seminar .seminar-content li .slide-txt h3 {
    margin-bottom: 3.2vw;
    font-size: 3.2vw;
  }
  body#seminar section.seminar .seminar-content li .slide-txt p {
    font-size: 2.66vw;
  }
}
body#seminar section.seminar .seminar-content li .tag span {
  margin: 0 16px 16px 0;
  padding: 3px 10px;
  line-height: 1;
  font-size: 16px;
  display: inline-block;
  font-weight: bold;
}
@media all and (-ms-high-contrast: none) {
  body#seminar section.seminar .seminar-content li .tag span {
    padding: 5px 10px 0;
  }
}
body#seminar section.seminar .seminar-content li .tag span.online {
  border: 1px #003981 solid;
  color: #003981;
}
body#seminar section.seminar .seminar-content li .tag span.before {
  color: #787878;
  background: #ebebeb;
}
body#seminar section.seminar .seminar-content li .tag span.accepting {
  color: #fff;
  background: #003981;
}
body#seminar section.seminar .seminar-content li .tag span.closed {
  border: 1px #dcdcdc solid;
  color: #969696;
}
body#seminar section.seminar .seminar-content li .tag-top {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}
body#seminar section.seminar .seminar-content li .tag-top span {
  margin-bottom: 0;
}
body#seminar section.seminar .seminar-content li .slide-txt {
  font-weight: bold;
}
body#seminar section.seminar .seminar-content li .slide-txt h3 {
  margin: 10px 0 30px;
  text-decoration: underline;
}
body#seminar section.seminar .seminar-content li .slide-txt span.date {
  margin-top: auto;
  color: #969696;
  font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 14px;
}
body#seminar section.seminar .seminar-content li.closed h3 {
  color: #969696;
}
body#seminar section.seminar .seminar-content li.closed .slide-txt span.online {
  border: 1px #dcdcdc solid;
  color: #969696;
}
body#seminar section.seminar .seminar-btn {
  width: 350px;
  height: 70px;
  margin: 60px auto 0 auto;
}
body#seminar section.seminar .seminar-btn a {
  line-height: 70px;
  font-size: 22px;
  padding-right: 0;
  font-size: 22px;
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 99px;
  box-sizing: border-box;
  font-weight: bold;
  background: #003981;
  color: #fff;
  text-align: right;
  position: relative;
  text-align: center;
}
body#seminar section.seminar .seminar-btn a:hover {
  opacity: 0.85;
}
body#seminar section.seminar .seminar-btn a::before {
  width: 20px;
  height: 18px;
  content: "";
  background: url("/assets/images/common/ic_contact.png");
  background-size: 20px 18px;
  position: absolute;
  top: 13px;
  left: 20px;
}
@media screen and (max-width: 999px) {
  body#seminar section.seminar .seminar-btn a {
    padding-right: 29.3vw;
    font-size: 3.73vw;
  }
}
body#seminar section.seminar .seminar-btn a::before {
  content: none;
}
@media screen and (max-width: 999px) {
  body#seminar section.seminar {
    padding: 17.33vw 0 10.93vw;
  }
  body#seminar section.seminar h2 {
    margin-bottom: 60px;
    text-align: center;
    color: #003981;
    font-size: 36px;
    font-weight: bold;
  }
  body#seminar section.seminar h2 span {
    margin-bottom: 15px;
    display: block;
    color: #787878;
    font-family: "Barlow", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
    font-size: 20px;
  }
}
@media screen and (max-width: 999px) and (max-width: 999px) {
  body#seminar section.seminar h2 {
    margin-bottom: 8.5vw;
    font-size: 5.33vw;
  }
  body#seminar section.seminar h2 span {
    margin-bottom: 1.3vw;
    font-size: 2.66vw;
  }
}
@media screen and (max-width: 999px) {
  body#seminar section.seminar .seminar-inner {
    margin: 0 auto;
    width: 86.66vw;
    max-width: auto;
  }
  body#seminar section.seminar .seminar-content {
    padding: 5px 5px 5px 15px;
    display: flex;
    width: 100%;
  }
  body#seminar section.seminar .seminar-content li {
    width: calc(33.3333333333% - 40px);
    display: flex;
    transition: 0.3s all;
  }
  body#seminar section.seminar .seminar-content li:hover {
    opacity: 0.7;
  }
  body#seminar section.seminar .seminar-content li .slide-li {
    margin: 5px 15px 5px 5px;
    background: #fff;
    box-shadow: 0 0 8px #c5c5c5;
    box-sizing: border-box;
  }
  body#seminar section.seminar .seminar-content li .slide-li img {
    width: 100%;
  }
  body#seminar section.seminar .seminar-content li .slide-txt {
    padding: 30px 35px;
  }
  body#seminar section.seminar .seminar-content li .slide-txt h3 {
    margin-bottom: 30px;
    line-height: 1.8;
    color: #003981;
    font-size: 20px;
    font-weight: bold;
  }
}
@media screen and (max-width: 999px) and (min-width: 1000px) {
  body#seminar section.seminar .seminar-content li .slide-li {
    display: flex;
  }
  body#seminar section.seminar .seminar-content li a {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  body#seminar section.seminar .seminar-content li a .slide-ph img {
    width: 100%;
  }
  body#seminar section.seminar .seminar-content li a .slide-txt {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
@media screen and (max-width: 999px) and (min-width: 1000px) and (max-width: 1270px) {
  body#seminar section.seminar .seminar-content button.slick-prev {
    left: 0;
  }
  body#seminar section.seminar .seminar-content button.slick-next {
    right: 0;
  }
}
@media screen and (max-width: 999px) and (max-width: 999px) {
  body#seminar section.seminar .seminar-content {
    padding: 0;
    display: block;
  }
  body#seminar section.seminar .seminar-content li {
    width: 100%;
  }
  body#seminar section.seminar .seminar-content li .slide-li {
    margin: 0 0 4vw;
  }
  body#seminar section.seminar .seminar-content li .slide-li a {
    display: flex;
    flex-wrap: nowrap;
  }
  body#seminar section.seminar .seminar-content li .slide-ph {
    width: 40vw;
    height: 40vw;
  }
  body#seminar section.seminar .seminar-content li .slide-ph img {
    width: 100%;
  }
  body#seminar section.seminar .seminar-content li .slide-txt {
    padding: 5.5vw 5.6vw 5vw;
    width: calc(100% - 40vw);
    box-sizing: border-box;
  }
  body#seminar section.seminar .seminar-content li .slide-txt h3 {
    margin-bottom: 3.2vw;
    font-size: 3.2vw;
  }
  body#seminar section.seminar .seminar-content li .slide-txt p {
    font-size: 2.66vw;
  }
}
@media screen and (max-width: 999px) {
  body#seminar section.seminar .seminar-content li .slide-li a {
    flex-wrap: wrap !important;
  }
  body#seminar section.seminar .seminar-content li .tag span {
    margin-right: 1.73vw;
    padding: 0 1.33vw;
    height: 4.8vw;
    line-height: 4.8vw;
    font-size: 2.66vw;
    font-weight: bold;
    text-align: center;
  }
  body#seminar section.seminar .seminar-content li .slide-ph {
    width: 100% !important;
    height: auto !important;
  }
  body#seminar section.seminar .seminar-content li .slide-ph img {
    height: 100%;
  }
  body#seminar section.seminar .seminar-content li .tag-top {
    padding: 1.86vw 2.66vw;
  }
  body#seminar section.seminar .seminar-content li .tag-btm {
    width: 100%;
  }
  body#seminar section.seminar .seminar-content li .slide-txt {
    padding: 1.86vw 4vw 3.06vw !important;
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  body#seminar section.seminar .seminar-content li .slide-txt h3 {
    margin: 0 !important;
    flex-grow: 1;
    font-size: 3.46vw;
  }
  body#seminar section.seminar .seminar-content li .slide-txt span.date {
    margin-top: 2.66vw;
    width: 100%;
    font-size: 2.66vw;
    display: block;
  }
  body#seminar section.seminar .seminar-content li.closed h3 {
    color: #969696;
  }
  body#seminar section.seminar .seminar-btn {
    width: 100%;
    height: 12.5vw;
    margin: 6vw auto 0 auto;
  }
  body#seminar section.seminar .seminar-btn a {
    line-height: 12.5vw;
    padding-right: 0;
  }
}