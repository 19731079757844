@use '../setting' as *;
@use '../mixin/mixin' as *;

/* Clearfix
------------------------*/
html {
  font-size: $base-font-size;
}

body {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  // -webkit-font-smoothing: $font-smoothing;
  // -moz-osx-font-smoothing: grayscale;
  // font-smoothing: $font-smoothing;
  text-rendering: $text-rendering;
  font-family: $font-base;
  font-size: $base-font-size;
  font-weight: 500;
  color: $base-font-color;
  font-feature-settings: 'pkna';
  background-color: $base-background-color;
  // -webkit-font-smoothing: antialiased;
  line-height: 1;
  position: relative;
  text-align: justify;
}

body.sp-menu-open {
  @include media(sp) {
    height: 100%;
    overflow: hidden;
  }
}

.cf:after {
  content: '';
  display: block;
  clear: both;
}

a {
  text-decoration: none;
  color: $color-black2;
  @include fade;
  @include media(pc) {
    &[href^="tel:"] {
      pointer-events: none;
    }
  }
}
a:focus, *:focus {
  outline: none;
}

p {
  line-height: 2;
  font-size: 16px;
  @include media(sp) {
    line-height: 1.8;
    font-size: 3.2vw;
  }
}

section {
  position: relative;
}

.is-pc {
  @include media(sp) {
    display: none;
  }
}
.is-sp {
  display: none;
  @include media(sp) {
    display: block;
  }
}

.fade {
  transition: .3s ease-in-out;
  &:hover {
    opacity: .7;
  }
}

button.slick-arrow {
  margin: 0;
  border: none;
  width: 23px;
  height: 51px;
  font-size: 0;
  &:hover {
    @include fade;
  }
}
button.slick-prev,
button.slick-prev:hover,
button.slick-prev:focus {
  left: -30px;
  background: url('/assets/images/common/ic_arrow_prev_pc.png') no-repeat;
}
button.slick-next,
button.slick-next:hover,
button.slick-next:focus {
  right: -30px;
  background: url('/assets/images/common/ic_arrow_next_pc.png') no-repeat;
}
button.slick-prev,
button.slick-next,
button.slick-prev:hover,
button.slick-next:hover,
button.slick-prev:focus,
button.slick-next:focus {
  background-size: 23px 51px;
  z-index: 1;
}

button.slick-prev:before,
button.slick-next:before {
  font-size: 0;
}

@include media(sp) {
  button.slick-arrow {
    width: 8.6vw;
    height: 11.3vw;
  }
  button.slick-prev,
  button.slick-prev:hover,
  button.slick-prev:focus {
    left: 0;
    background: url('/assets/images/common/ic_arrow_prev_sp.png') no-repeat;
    background-position: 2.1vw;
  }
  button.slick-next,
  button.slick-next:hover,
  button.slick-next:focus {
    right: 0;
    background: url('/assets/images/common/ic_arrow_next_sp.png') no-repeat;
    background-position: 4.1vw;
  }
  button.slick-prev,
  button.slick-next,
  button.slick-prev:hover,
  button.slick-next:hover,
  button.slick-prev:focus,
  button.slick-next:focus {
    background-size: 2.4vw 4.53vw;
  }
}

//loading
#loading {
  width: 100vw;
  height: 100vh;
  transition: 1s all;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  .loading-bg {
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    position: absolute;
  }
  &.loaded {
    opacity: 0;
    visibility: hidden;
  }
  .spinner {
    margin: auto;
    width: 40px;
    height: 40px;
    background: $color-blue;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    position: relative;
    top: 0;
    left: 0;
    z-index: 10000;
  }
  @-webkit-keyframes sk-rotateplane {
    0% { -webkit-transform: perspective(120px) }
    50% { -webkit-transform: perspective(120px) rotateY(180deg) }
    100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
  }
  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    } 50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    } 100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
}
