@use '../setting' as *;
@use '../mixin/mixin' as *;
body#home {
  main {
    .home-bg{
      padding-top: 65px;
      width: 100%;
      background-image : url('/assets/images/home/mv3_pc_02.png');
      background-size: cover;
      background-position: center;
      @media (max-width: 1270px) and  (min-width: 1201px){
        padding: 120px 0 80px 0;
      }
      @media (max-width: 1200px) and  (min-width: 1000px){
        padding: 120px 0 60px 0;
      }
      @include media(sp){
        padding: 80px 0 0 0;
      }
      @media (max-width: 999px) and  (min-width: 600px){
        padding: 160px 0 80px 0;
      }
    }
    .home-top{
      margin: 0 auto;
      max-width: 1800px;
      position: relative;
      box-sizing: border-box;
      height: 80vh;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding-left: 5%;   // 左右に5%の余白
      padding-right: 5%;
      @media (max-width: 1800px) and (min-width: 1301px){
        height: 70vh;
      }
      @media (max-width: 1270px) {
        display: block;
        height: auto;
      }
      @media (max-width: 1000px){
        display: block;
        height: 45vh;
      }
      @media (max-width: 767px){
        display: block;
        height: 62vh;
      }
      @media (max-width: 376px){
        display: block;
        height: 75vh;
      }
      // .home-top-img{
      //   flex: 1;
      //   display: block;
      //   max-width: 1200px;
      //   min-width: 640px;
      //   margin-right: 5vw;

      //   .home-top-img-pc{
      //     display: block;
      //   }
      //   .home-top-img-sp{
      //     display: none;
      //   }

      //   @media (max-width: 1500px) {
      //     min-width: 500px;
      //   }
      //   // @media (max-width: 1300px) {
      //   //   margin: 0 auto;
      //   //   text-align: center;
      //   // }
      //   @media (max-width: 1270px) {
      //     margin: 0 auto;
      //     text-align: center;
      //   }
      //   @media (max-width: 767px) {
      //     max-width: 100%;
      //     min-width: 100%;

      //     .home-top-img-pc{
      //       display: none;
      //     }
      //     .home-top-img-sp{
      //       display: inline-block;
      //     }
      //   }
      //   img{
      //     max-width: 800px;
      //     width: 100%;
      //     height: auto;
      //     @media (max-width: 767px) {
      //       max-width: 100%;
      //     }
      //     @media (min-width: 768px) and (max-width: 1270px) {
      //       max-width: 600px;
      //     }
      //   }
      // }
      .home-top-img {
        flex: 1;
        display: block;
        max-width: 1200px;
        min-width: 640px;
        margin-right: 5vw;
      
        .home-top-img-pc {
          display: block;
        }
        .home-top-img-sp {
          display: none;
        }
      
        @media (max-width: 1500px) {
          min-width: 500px;
        }
      
        @media (max-width: 1270px) {
          margin: 0 auto;
          text-align: center;
        }
      
        @media (max-width: 767px) {
          max-width: 100%;
          width: 100%;
          min-width: 0 !important;
      
          .home-top-img-pc {
            display: none;
          }
          .home-top-img-sp {
            display: block;
            width: 100%;
          }
        }
      
        img {
          max-width: 800px;
          width: 100%;
          height: auto;
      
          @media (min-width: 768px) and (max-width: 1270px) {
            max-width: 600px;
          }
        }
      }
      
      .home-contact{
        flex: 1;
        margin-top: 210px;
        height: auto;
        max-height: 180px;

        @media (max-width: 1270px) {
          margin-top: 40px;
        }

        @include media(sp) {
          padding: 0 6.6vw 0;
          height: auto;
          box-sizing: border-box;
          h2 {
            margin-bottom: 5.3vw;
            font-size: 6.66vw;
          }
          .home-btn-list {
            flex-direction: column;
            li {
              width: 100%;
              height: 12.5vw;
              a {
                line-height: 12.5vw;
              }
              &:last-child {      
                margin: 0; 
                a {
                  font-size: 18px;
                }
              }
            }
          }
        }
        

        .home-btn-list {
          display: flex;
          justify-content: center;
          align-items: center;

          li {
            width: 350px;
            height: 70px;
            
            @media (max-width: 1800px) {
              width: 300px;
              height: 60px;
            }
            @media (max-width: 1300px) {
              width: 260px;
              height: 80px;
            }
            @media (max-width: 700px) {
              height: 60px;
            }

            a {
              line-height: 70px;
              font-size: 22px;
              text-align: center;
            }
            &:first-child {
              @media (max-width: 1270px) and (min-width: 600px) {
                margin-right: 20px;
              }
              @include media(sp) {
               margin: 0;
              }
              a {
                @include btn-contact2;
                
                &::before{
                  content: none;
                }

                &{
                  text-align: center;
                  font-size: 18px;
                  padding-right: 0;
                }

                @media (max-width: 1800px) {
                  font-size: 18px;
                }

                @include media(sp) {
                  text-align: center;
                  padding-right: 0;
                  font-size: 3.73vw !important;
                }
              }
            }
            &:last-child {
              margin-left: 40px;

              a {
                @include btn-contact2;
                background: #fff;
                color: #003981;
                outline: 2px solid #003981;

                &{
                  text-align: center;
                  font-size: 18px;
                  padding-right: 0;
                }
                @include media(sp){
                  padding-right: 0;
                }
                &::before{
                  content: none;
                }
              }

              @media (max-width: 1270px) and (min-width: 600px) {
                margin-left: 20px;
              }
              @media (max-width:1000px) and (min-width:600px){
                margin-top: 0;
              }
              @include media(sp) {
                margin: 0;
              }
            }
          }
        }
        .home-btn-list {
          @media (max-width:1000px) and (min-width:600px) {
            display: flex;
            flex-direction: row;
          }
        }
      }
    }

    .result-top{
      .result-inner {
        overflow: hidden;
        .scrolling-logos {
          margin-top: 40px;
          display: flex;
          gap: 40px;
          animation: scroll 20s linear infinite; /* 横スクロールアニメーションを設定 */

          &:hover {
            animation-play-state: paused;
          }
          li {
            display: inline-block;
            flex-shrink: 0;
          }
          img {
            width: auto;
            height: 3.7vh;
            display: block;
            object-fit: contain;
          }
          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-90%);
            }
          }
        }
      }
      @include media(sp) {
        .result-inner {
          padding: 0;
          .scrolling-logos{
            margin-top: 20px;
            animation: scroll 20s linear infinite; /* 横スクロールアニメーションを設定 */
            gap: 20px;
            img{
              height: 2vh;
            }
          }
        }
      }
    }

    .home-mv {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      overflow: hidden;
      .home-mv-ph {
        position: absolute;
        top: 0;
        right : 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        animation: bgAnime 15s infinite;
        background-size: cover;
        background-position: center;
      }
      .ph1 {
        background-image : url('/assets/images/home/mv1_pc_02.png');
      }
      .ph2 {
        background-image : url('/assets/images/home/mv2_pc_02.png');
        animation-delay  : 5s;
      }
      .ph3 {
        background-image : url('/assets/images/home/mv3_pc_02.png');
        animation-delay  : 10s;
      }
      @keyframes bgAnime {
        0% { opacity: 0; }
        5% { opacity: 1; }
        33.3% { opacity: 1; }
        40% { opacity: 0; }
        100% { opacity: 0; }
      }
      h1 {
        margin: 0 auto;
        max-width: 1000px;
        padding: 0 10px;
        padding-top: 58vh;
        max-width: 1200px;
        font-size: 26px;
        @include bold;
        position: relative;
        span {
          margin-bottom: 20px;
          display: block;
          width: 424px;
          height: 61px;
          img {
            width: 100%;
          }
        }
      }
    }
    .wrap {
      background: #fff;
      position: relative;
    }
    @include media(sp) {
      .home-mv {
        .ph1 {
          background-image : url('/assets/images/home/mv1_sp_02.png');
        }
        .ph2 {
          background-image : url('/assets/images/home/mv2_sp_02.png');
        }
        .ph3 {
          background-image : url('/assets/images/home/mv3_sp_02.png');
        }
        h1 {
          padding: 0 25px;
          padding-top: 58.2vh;
          max-width: 100%;
          font-size: 3.73vw;
          letter-spacing: 1px;
          box-sizing: border-box;
          position: absolute;
          top: 0;
          span {
            width: 64.66vw;
            height: 9.33vw;
          }
        }
      }
    }
  }

  section.intro {
    padding: 50px 0;
    background: url('/assets/images/home/intro_bg1_pc.png') no-repeat;
    background-size: 1426px 540px;
    background-position: left calc(50% + 713px) top 108px;
    .intro-inner {
      margin: 0 auto;
      max-width: 1300px;
      height: 700px;
      position: relative;
    }
    .intro-img {
      width: 590px;
      height: 700px;
      position: absolute;
      top: 0;
      left: auto;
    }
    img {
      width: 100%;
    }
    h1{
      width: 600px;
      line-height: 1.57;
      color: $color-blue;
      font-size: 24px;
      font-weight: bold;
      position: absolute;
      top: 180px;
      right: -100px;
      z-index: 1;
    }
    h2 {
      width: 600px;
      line-height: 1.57;
      color: $color-blue;
      font-size: 42px;
      font-weight: bold;
      position: absolute;
      top: 220px;
      right: 0;
      z-index: 1;
      span {
        margin-bottom: 10px;
        display: block;
        font-size: 28px;
      }
    }
    span{        
      margin-bottom: 10px;
      display: block;
      width: 600px;
      line-height: 1.57;
      color: $color-blue;
      font-size: 24px;
      font-weight: bold;
      position: absolute;
      top: 180px;
      right: 0;
      z-index: 1;
    }
    .intro-txt {
      width: 600px;
      position: absolute;
      top: 380px;
      right: 0;
      z-index: 1;
    }
    @include media(w1230) {
      .intro-img {
        right: 660px;
      }
    }
    @include media(w1300) {
      background: url('/assets/images/home/intro_bg2_pc.png') no-repeat;
      background-position: right 0 top 108px;
      background-size: 650px 540px;
      h2, .intro-txt {
        right: 0;
      }
    }
    // SP sec.intro
    @include media(sp) {
      padding: 21vw 0 25vw;
      max-width: 100%;
      height: auto;
      background: none;
      .intro-inner {
        height: auto;
      }
      .intro-img {
        width: 72%;
        height: auto;
        position: static;
      }
      h1,h2,span {
        width: 100%;
        line-height: 1.44;
        font-size: 6.93vw;
        position: static;
        box-sizing: border-box;
        span {
          font-size: 4vw;
        }
      }
      h1,span{
        font-size: 4vw;
        display: inline;
      }
      h2{
        margin-top: 10px;
        margin-bottom: 6.6vw;
        padding: 0 6.6vw;
      }
      span{
        padding-left: 6.6vw;
      }
      .intro-txt {
        margin-top: 10.6vw;
        padding: 0 6.6vw;
        width: 100%;
        position: static;
        box-sizing: border-box;
      }
    }
  }

  section.voice {
    padding: 95px 0 115px;
    @include bg-border;
    h2 {
      @include h2;
    }
    .voice-inner {
      margin: 0 auto;
      max-width: 1200px;
    }
    .voice-content {
      @include slide-content;
      li {
        .slide-txt {
          .slide-txt-logo {
            margin-top: auto;
            width: 220px;
            img {
              width: 100%;
            }
          }
          p {
            margin-top: 5px;
          }
        }
      }
    }
    @include media(sp) {
      padding: 13vw 6.6vw;
      overflow: hidden;
      .voice-inner {
        max-width: auto;
      }
      .voice-content {
        background: #fff;
        box-shadow: 0 0 8px #c5c5c5;
        button.slick-prev,
        button.slick-prev:hover,
        button.slick-prev:focus {
          left: -6.6vw;
        }
        button.slick-next,
        button.slick-next:hover,
        button.slick-next:focus {
          right: -6.6vw;
        }
        li {
          .slide-li {
            margin-bottom: 0;
            box-shadow: none;
          }
          .slide-txt {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            h3 {
              width: 100%;
            }
            .slide-txt-logo {
              width: 24vw;
            }
          }
        }
      }
    }
  }

  section.result {
    padding: 130px 0;
    h2 {
      margin-bottom: 50px;
      line-height: 1.8;
      text-align: center;
      font-size: 24px;
      @include bold;
      span {
        color: $color-blue;
        font-size: 36px;
        span {
          font-family: $font-en;
          font-size: 50px;
        }
      }

    }
    .result-inner {
      margin: 0 auto;
      padding: 0 10px;
      max-width: 1200px;
      img {
        width: 100%;
      }
      ul {
        margin: 0 auto 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &:nth-child(1) {
          li:nth-child(1) { //pasona
            width: 158px;
          }
          li:nth-child(2) { //jra
            width: 98px;
          }
          li:nth-child(3) { //nanou
            width: 120px;
          }
          li:nth-child(4) { //routeinn
            width: 173px;
          }
          li:nth-child(5) { //ucan
            width: 112px;
          }
          li:nth-child(6) { //knowledgesuite
            width: 187px;
          }
        }
        &:nth-child(2) {
          max-width: 1140px;
          li:nth-child(1) { //midorianzen
            width: 110px;
          }
          li:nth-child(2) { //converse
            width: 146px;
          }
          li:nth-child(3) { //edwin
            width: 87px;
          }
          li:nth-child(4) { //jins
            width: 75px;
          }
          li:nth-child(5) { //pizzala
            width: 117px;
          }
          li:nth-child(6) { //sekaido
            width: 118px;
          }
        }
        &:nth-child(3) {
          margin-bottom: 0;
          max-width: 1120px;
          li:nth-child(1) { //jisin
            width: 99px;
          }
          li:nth-child(2) { //nishikawa
            width: 141px;
          }
          li:nth-child(3) { //roomclip
            width: 131px;
          }
          li:nth-child(4) { //hankyu
            width: 123px;
          }
          li:nth-child(5) { //type
            width: 182px;
          }
          li:nth-child(6) { //halmek
            width: 79.5px;
          }
        }
      }
    }
    @include media(sp) {
      padding: 16vw 4vw;
      h2 {
        margin-bottom: 8vw;
        font-size: 3.46vw;
        font-weight: bold;
        span {
          font-size: 4.8vw;
          span {
            font-size: 6.66vw;
          }
        }
      }
      .result-inner {
        padding: 0;
        max-width: auto;
        ul {
          margin-bottom: 0;
          align-items: center;
          li {
            margin-bottom: 6.6vw;
            width: calc(100% / 3) !important;
            img {
              margin: 0 auto;
              display: block;
            }
          }
          &:nth-child(1) {
            li:nth-child(1) img { //pasona
              width: 23.2vw;
            }
            li:nth-child(2) img { //jra
              width: 15.73vw;
            }
            li:nth-child(3) img { //nanou
              width: 18.4vw;
            }
            li:nth-child(4) img { //routeinn
              width: 25.46vw;
            }
            li:nth-child(5) img { //ucan
              width: 16.8vw;
            }
            li:nth-child(6) img { //knowledgesuite
              width: 23.3vw;
            }
          }
          &:nth-child(2) {
            width: 100%;
            li:nth-child(1) img { //midorianzen
              width: 15.6vw;
            }
            li:nth-child(2) img { //converse
              width: 20.8vw;
            }
            li:nth-child(3) img { //edwin
              width: 12.93vw;
            }
            li:nth-child(4) img { //jins
              width: 11.86vw;
            }
            li:nth-child(5) img { //pizzala
              width: 16.8vw;
            }
            li:nth-child(6) img { //sekaido
              width: 17.2vw;
            }
          }
          &:nth-child(3) {
            width: 100%;
            li:nth-child(1) img { //jisin
              width: 13.86vw;
            }
            li:nth-child(2) img { //nishikawa
              width: 20.66vw;
            }
            li:nth-child(3) img { //roomclip
              width: 18.93vw;
            }
            li:nth-child(4) img { //hankyu
              width: 17.73vw;
            }
            li:nth-child(5) img { //type
              width: 24.93vw;
            }
            li:nth-child(6) img { //halmek
              width: 12vw;
            }
          }
        }
      }
    }
  }

  section.feature {
    padding: 75px 0 145px;
    h2 {
      margin: 0 auto 80px;
      padding-top: 180px;
      max-width: 1400px;
      height: 280px;
      line-height: 1.8;
      background: url('/assets/images/home/feature_bg_pc.jpg') no-repeat center;
      background-size: cover;
      text-align: center;
      font-family: $font-en;
      font-size: 20px;
      @include bold;
      span {
        color: $color-blue;
        font-size: 40px;
        span {
          font-family: $font-en;
          font-size: 50px;
        }
        span.img {
          margin-right: 3px;
          width: 236px;
          height: 39px;
          display: inline-block;
          img {
            width: 100%;
            vertical-align: middle;
          }
        }
      }
    }
    .feature-wrap {
      background:
        url('/assets/images/home/feature_bg1_pc.png') no-repeat,
        url('/assets/images/home/feature_bg2_pc.png') no-repeat;
      background-position:
        right 0 top 190px,
        left 0 top 840px;
      background-size:
        1100px 521px,
        1100px 437px;
    }
    .feature-inner {
      margin: 0 auto;
      max-width: 1400px;
      background:
        url('/assets/images/home/feature_bg_num1.png') no-repeat,
        url('/assets/images/home/feature_bg_num2.png') no-repeat,
        url('/assets/images/home/feature_bg_num3.png') no-repeat;
      background-position:
        right 110px top 105px,
        left 480px top 600px,
        right 100px top 1180px;
      background-size:
        110px 198px,
        141px 202px,
        133px 204px;
      h3 {
        margin-bottom: 55px;
        font-size: 24px;
        @include bold;
        span {
          margin-left: -20px;
          font-size: 65px;
          color: $color-blue;
          letter-spacing: -3px;
        }
      }
      .feature-block-ph {
        img {
          width: 100%;
        }
      }
      .feature-block1 {
        margin-bottom: 50px;
        height: 452px;
        h3 {
          margin-top: 170px;
          float: right;
          text-align: left;
        }
        .feature-block-ph {
          float: left;
          width: 700px;
          height: 452px;
        }
        .feature-block-txt {
          float: right;
        }
        h3,
        .feature-block-txt {
          padding-right: 70px;
          width: 580px;
        }
      }
      .feature-block2 {
        margin-bottom: 120px;
        height: 542px;
        h3 {
          margin: 175px 0 35px;
          float: left;
        }
        .feature-block-ph {
          float: right;
          width: 761px;
          height: 541px;
        }
        .feature-block-txt {
          float: left;
        }
        h3,
        .feature-block-txt {
          padding: 0 0 0 20px;
          width: 600px;
        }
      }
      .feature-block3 {
        h3 {
          margin-top: 100px;
        }
      }
    }
    // w1400 feature
    @include media(w1400) {
      .feature-inner {
        background-position:
          right 60px top 105px,
          left 480px top 600px,
          right 100px top 1180px;
        .feature-block1 {
          .feature-block-ph {
            width: calc(100% - 650px);
            overflow: hidden;
            position: relative;
            img {
              width: 700px;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
          h3,
          .feature-block-txt {
            padding: 0 40px 0 30px;
          }
        }
        .feature-block2 {
          .feature-block-ph {
            width: calc(100% - 630px);
            overflow: hidden;
            position: relative;
            img {
              width: 749px;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
        .feature-block3 {
          .feature-block-ph {
            img {
              left: 0;
            }
          }
        }
      }
    }
    // SP feature
    @include media(sp) {
      padding: 18.6vw 0 0;
      h2 {
        margin: 0 auto;
        padding-top: 17.5vw;
        max-width: auto;
        width: calc(100% - 8vw);
        height: 53.3vw;
        line-height: 1.7;
        background: url('/assets/images/home/feature_bg_sp.jpg') no-repeat center;
        background-size: cover;
        font-size: 3.46vw;
        box-sizing: border-box;
        position: relative;
        z-index: 2;
        span {
          font-size: 5.33vw;
          span {
            font-size: 6.66vw;
          }
          span.img {
            width: 30.66vw;
            height: 5.866vw;
          }
        }
      }
      .feature-wrap {
        padding-top: 24.66vw;
        position: relative;
        top: -2.66vw;
        background:
          url('/assets/images/home/feature_bg1_sp.png') no-repeat,
          url('/assets/images/home/feature_bg2_sp.png') no-repeat,
          url('/assets/images/home/feature_bg3_sp.png') no-repeat;
        background-position:
          right 0 top 0,
          left 0 top 42vw,
          right 0 top 267vw;
        background-size:
          64.8vw 65.4vw,
          74.5vw 102.4vw,
          74.6vw 96.4vw;
      }
      .feature-inner {
        margin: 0;
        max-width: auto;
        width: 100%;
        background:
          url('/assets/images/home/feature_bg_num1.png') no-repeat,
          url('/assets/images/home/feature_bg_num2.png') no-repeat,
          url('/assets/images/home/feature_bg_num3.png') no-repeat;
        background-position:
          right 4.5vw top 20vw,
          left 7.4vw top 139.5vw,
          right 6vw top 250.8vw;
        background-size:
          14.6vw auto,
          18.6vw auto,
          17.3vw auto;
        h3 {
          margin-bottom: 8vw;
          font-size: 4vw;
          span {
            margin-left: -5px;
            font-size: 9.33vw;
            letter-spacing: 0px;
          }
        }
        .feature-block1 {
          margin-bottom: 10vw;
          h3 {
            margin: 0 auto 8vw;
            order: 2;
            text-align: right;
            span {
              letter-spacing: -2px;
            }
          }
          .feature-block-ph {
            margin: 0 auto 7.3vw 0;
            width: 71.466vw;
            height: 46.133vw;
            order: 1;
          }
          .feature-block-txt {
            order: 3;
          }
        }
        .feature-block2 {
          margin-bottom: 24vw;
          h3 {
            margin: 0 auto 8vw;
            order: 2;
            text-align: right;
          }
          .feature-block-ph {
            margin: 0 0 9.3vw auto;
            width: 65.7vw;
            order: 1;
          }
          .feature-block-txt {
            order: 3;
          }
        }
        .feature-block3 {
          margin-bottom: 0;
          padding-bottom: 25.3vw;
        }
        .feature-block1,
        .feature-block2,
        .feature-block3 {
          height: auto;
          display: flex;
          flex-wrap: wrap;
          h3,
          .feature-block-ph,
          .feature-block-txt {
            float: none;
          }
          h3,
          .feature-block-txt {
            padding: 0;
            width: 86.8vw;
          }
          .feature-block-txt {
            margin: 0 auto;
          }
          .feature-block-ph {
            height: auto;
          }
        }
      }
    }
  }

  section.flow {
    margin-top: 125px;
    padding: 120px 0;
    @include bg-border;
    h2 {
      @include h2;
    }
    .flow-inner {
      margin: 0 auto;
      padding: 0 10px;
      max-width: 1200px;
      .kome {
        margin-top: 20px;
        font-size: 15px;
      }
      .flow-tab-btn {
        margin: 0 auto;
        max-width: 1100px;
        ul {
          display: flex;
          position: relative;
          top: 1px;
          background: #fff;
        }
        li {
          padding: 0 20px;
          border: 1px $color-gray3 solid;
          border-right: none;
          height: 70px;
          flex-grow: 1;
          line-height: 70px;
          background: $color-gray2;
          color: $color-gray1;
          font-size: 18px;
          box-sizing: border-box;
          text-align: center;
          overflow: hidden;
          cursor: pointer;
          transition: .3s all;
          &.tab4 .ti {
            font-size: 17px;
          }
          &:last-child {
            border-right: 1px $color-gray3 solid;
          }
          span {
            font-weight: bold;
            &.num{
              font-family: $font-en;
            }
            &.ti {
              display: none;
            }
          }
          &.is-active {
            border-bottom: 1px #fff solid;
            cursor: auto;
          }
          @include media(pc) {
            &:hover,
            &.is-active {
              background: #fff;
              span {
                display: inline-block;
                &.num {
                  margin-right: 20px;
                  color: $color-blue;
                  font-family: $font-en;
                }
                &.ti {
                  color: $color-black2;
                }
              }
            }
          }
        }
        @include media(pc) {
          ul:hover {
            // is-active以外のタブhover時
            li:hover {
              flex-grow: 2;
              span {
                display: inline-block;
                &.num {
                  margin-right: 20px;
                }
              }
            }
            // 他のタブにhoverした時のis-activeタブ
            li.is-active {
              flex-grow: 1;
              text-align: center;
              span {
                margin-right: 0;
                display: block;
                &.ti {
                  display: none;
                }
              }
            }
            // is-activeにhoverした時
            li.is-active:hover {
              span {
                display: inline-block;
                &.num {
                  margin-right: 20px;
                }
              }
            }
          }
        }
      }
      .flow-tab-content {
        padding: 50px 100px;
        border: 1px $color-gray3 solid;
        max-width: 1200px;
        background: #fff;
        box-sizing: border-box;
        // height: 700px;
        // overflow-y: scroll;
        // &.scroll {
        //   height: calc(100vh - 180px);
        // }
        img {
          margin: 0 auto 40px;
          max-width: 700px;
          display: block;
        }
        .panel {
          display: none;
        }
        @include media(pc) {
          .panel.is-show {
            display: block;
          }
        }
      }
    }
    // SP flow
    @include media(sp) {
      margin-top: 21.3vw;
      padding: 14.6vw 0 20vw;
      .flow-inner {
        padding: 0;
        max-width: 100%;
        p.kome {
          margin-top: 4vw;
          padding: 0 6.6vw;
          font-size: 3.2vw;
        }
        .flow-tab-btn {
          max-width: 100%;
          ul {
            display: flex;
            position: relative;
            top: 1px;
            z-index: 1;
            background: #fff;
          }
          li {
            padding: 0 2.66vw;
            height: 11.3vw;
            line-height: 11.3vw;
            font-size: 3.46vw;
            &.tab4 .ti {
              font-size: 3.46vw;
            }
            &:last-child {
              border-right: none;
            }
          }
          .slick-current {
            width: 77.4vw;
          }
          .slick-current li {
            border-bottom: 1px #fff solid;
            background: #fff;
            span {
              display: inline-block;
              &.num {
                margin-right: 20px;
                color: $color-blue;
                font-family: $font-en;
              }
              &.ti {
                color: $color-black2;
              }
            }
          }
        }
        .flow-tab-content {
          padding: 8vw 6.6vw 13.3vw;
          border-right: none;
          border-left: none;
          max-width: 100%;
          height: auto;
          overflow-y: visible;
          &.scroll {
            height: auto;
          }
          img {
            margin-bottom: 6.6vw;
            width: 100%;
          }
          .flow-tab-content-txt p {
            font-size: 3.2vw;
            text-align: left;
          }
          button.slick-prev,
          button.slick-prev:hover,
          button.slick-prev:focus {
            top: 34.4vw;
          }
          button.slick-next,
          button.slick-next:hover,
          button.slick-next:focus {
            top: 34.4vw;
          }
        }
      }
    }
  }

  section.team {
    padding: 145px 0 180px;
    background:
      url('/assets/images/home/team_bg1_pc.png') no-repeat,
      url('/assets/images/home/team_bg2_pc.png') no-repeat;
    background-position:
      left calc(50% - 280px) top 160px,
      right calc(50% - 200px) top 620px;
    background-size:
      750px 353px,
      679px 277px;
    h2 {
      @include h2;
      & {
        margin-bottom: 18px;
      }
    }
    .team-h2-kome {
      margin-bottom: 80px;
      font-size: 15px;
      text-align: center;
    }
    .team-inner {
      margin: 0 auto;
      padding: 0 10px;
      max-width: 1200px;
      display: flex;
    }
    .team-list {
      width: 640px;
      ul {
        position: relative;
        height: 440px;
        background: url('/assets/images/home/team_bg_tri_pc.png') no-repeat;
        background-size: 300px 217px;
        background-position: top 110px left 180px;
      }
      li {
        width: 230px;
        height: 230px;
        border-radius: 50%;
        background: #fff;
        box-sizing: border-box;
        position: absolute;
        h3 {
          border: 2px $color-blue dotted;
          position: relative;
          z-index: 2;
          width: 230px;
          height: 230px;
          line-height: 1.7;
          border-radius: 50%;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          color: $color-blue;
          box-sizing: border-box;
          cursor: pointer;
          transition: .3s all;
          span {
            margin-top: 5px;
            font-size: 14px;
            font-weight: normal;
            display: block;
          }
          &:hover {
            color: #fff;
            background: $color-blue;
          }
        }
      }
      li.team-list1 {
        top: 0;
        left: 210px;
        h3 {
          padding-top: 105px;
          line-height: 1;
        }
      }
      li.team-list2 {
        top: 210px;
        left: 40px;
        h3 {
          padding-top: 88px;
        }
      }
      li.team-list3 {
        top: 210px;
        left: 370px;
        h3 {
          padding-top: 70px;
        }
      }
      li.is-active {
        border: none;
        h3 {
          width: 230px;
          height: 230px;
          color: #fff;
          background: $color-blue;
          cursor: auto;
          &:hover {
            opacity: 1;
          }
        }
      }
      li.team-list1.is-active {
        &::before {
          width: 210px;
          height: 1px;
          border-top: 1px $color-blue solid;
          position: absolute;
          content: '';
          z-index: 1;
          top: 120px;
          left: 228px;
        }
      }
      li.team-list2.is-active {
        &::before {
          width: 466px;
          height: 30px;
          border: 1px $color-blue solid;
          border-top: none;
          position: absolute;
          content: '';
          z-index: 1;
          top: 228px;
          left: 110px;
        }
        &::after {
          width: 30px;
          height: 180px;
          border: 1px $color-blue solid;
          border-bottom: none;
          border-right: none;
          position: absolute;
          content: '';
          z-index: 1;
          top: 50px;
          left: 577px;
        }
      }
      li.team-list3.is-active {
        &::before {
          width: 21px;
          height: 64px;
          border: 1px $color-blue solid;
          border-top: none;
          border-left: none;
          position: absolute;
          content: '';
          z-index: 1;
          top: 50px;
          left: 226px;
        }
        &::after {
          width: 30px;
          height: 1px;
          border-top: 1px $color-blue solid;
          position: absolute;
          content: '';
          z-index: 1;
          top: 50px;
          left: 247px;
        }
      }
    }
    .team-txt {
      margin-left: auto;
      padding: 65px 50px;
      position: relative;
      z-index: 2;
      border: 1px $color-blue solid;
      width: calc(100% - 640px);
      max-width: 600px;
      box-sizing: border-box;
      background: #fff;
      h4 {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px $color-gray3 solid;
        color: $color-blue;
        font-size: 20px;
        font-weight: bold;
      }
      .team-txt-content {
        display: none;
        &.is-show {
          display: block;
        }
        // // p {
        //   // letter-spacing: -0.5px;
        // }
      }
    }
    // SP team
    @include media(sp) {
      padding: 24vw 0 14.6vw;
      background: transparent;
      h2 {
        @include h2;
        margin-bottom: 2vw !important;
        line-height: 1.5;
      }
      .team-h2-kome {
        margin-bottom: 8vw;
        font-size: 2.66vw;
      }
      .team-inner {
        margin: 0;
        padding: 0;
        max-width: 100%;
        display: block;
      }
      .team-list {
        margin: 0 0 7.2vw;
        width: 100%;
        height: 75.66vw;
        box-sizing: border-box;
        ul {
          height: 75.66vw;
          background: url('/assets/images/home/team_bg_tri_sp.png') no-repeat;
          background-size: 46.933vw 34vw;
          background-position: top 22.5vw left 26vw;
        }
        li {
          width: 39.33vw;
          height: 39.33vw;
          h3 {
            width: 39.33vw;
            height: 39.33vw;
            font-size: 3.46vw;
            span {
              margin-top: 0;
              font-size: 2.6vw;
            }
          }
          &.is-active h3 {
            width: 39.33vw;
            height: 39.33vw;
          }
        }
        li.team-list1 {
          top: 0;
          left: 30.13vw;
          h3 {
            padding-top: 17.8vw;
          }
        }
        li.team-list2 {
          top: 35.46vw;
          left: 5.33vw;
          h3 {
            padding-top: 15.2vw;
          }
        }
        li.team-list3 {
          top: 35.46vw;
          left: 55.34vw;
          h3 {
            padding-top: 13.7vw;
            line-height: 1.3;
            span {
              margin-top: 1vw;
            }
          }
        }
        li.team-list1.is-active::before {
          width: 0;
          height: 0;
        }
        li.team-list2.is-active,
        li.team-list3.is-active {
          &::before, &::after {
            content: none;
          }
        }
      }
      .team-txt {
        margin: 0 auto;
        padding: 10.6vw 8vw;
        width: 86.8vw;
        max-width: auto;
        box-sizing: border-box;
        h4 {
          margin-bottom: 3.3vw;
          padding-bottom: 3.3vw;
          font-size: 4vw;
        }
        .team-txt-content {
          display: none;
          &.is-show {
            display: block;
          }
        }
        .team-txt-content.is-show {
          position: relative;
          &::after {
            position: absolute;
            width: 1px;
            content: '';
            background: $color-blue;
          }
        }
        .team-txt1.is-show {
          &::before {
            position: absolute;
            content: '';
            width: 10px;
            height: 40vw;
            top: -52vw;
            left: calc(50% - 5px);
            background: #fff;
            z-index: -1;
          }
          &::after {
            height: 43.9vw;
            top: -54.5vw;
            left: 50%;
          }
        }
        .team-txt2.is-show {
          &::after {
            height: 8.4vw;
            top: -19vw;
            left: 10vw;
          }
        }
        .team-txt3.is-show {
          &::after {
            height: 8.4vw;
            top: -19vw;
            left: 60vw;
          }
        }
      }
    }
  }

  .block-faq-ph {
    margin-bottom: 110px;
    width: 100%;
    height: 380px;
    background: url("/assets/images/home/faq_bg_pc.jpg") no-repeat;
    background-size: cover;
    @include media(sp) {
      margin-bottom: 0;
      height: 58.66vw;
      background: url("/assets/images/home/faq_bg_sp.jpg") no-repeat;
      background-size: cover;
    }
  }

  section.faq {
    padding: 110px 0 150px;
    h2 {
      @include h2;
    }
    .faq-inner {
      margin: 0 auto;
      padding: 0 10px;
      max-width: 1000px;
      dl {
        margin-bottom: 10px;
        border: 1px $color-gray3 solid;
        width: 100%;
        background: $color-gray2;
        border-radius: 6px;
        box-sizing: border-box;
        dt {
          padding: 22px 100px 22px 65px;
          font-size: 18px;
          position: relative;
          cursor: pointer;
          @include fade;
          h3 {
            @include bold;
          }
          &::before {
            position: absolute;
            content: "Q. ";
            font-size: 26px;
            font-weight: bold;
            color: $color-blue;
            top: 16px;
            left: 20px;
          }
          &::after {
            position: absolute;
            content: "+";
            font-size: 30px;
            color: $color-blue;
            top: 14px;
            right: 22px;
          }
        }
        dt.is-active::after {
          content: "−";
          right: 18px;
          top: 16px;
        }
        dd {
          padding: 0 100px 22px 65px;
          display: none;
        }
      }
    }
    // SP faq
    @include media(sp) {
      padding: 11.33vw 0 17.33vw;
      .faq-inner {
        margin: 0 auto;
        padding: 0 4vw;
        max-width: auto;
        dl {
          margin-bottom: 2.66vw;
          border-radius: 3px;
          dt {
            padding: 5vw 12vw 5vw 10.66vw;
            line-height: 1.69;
            font-size: 3.46vw;
            &::before {
              font-size: 4vw;
              top: 4vw;
              left: 4vw;
            }
            &::after {
              font-size: 4vw;;
              top: 4vw;
              right: 4vw;
            }
          }
          dt.is-active::after {
            top: 4.4vw;
            right: 3.6vw;
          }
          dd {
            padding: 0 12vw 5.86vw 10.66vw;
          }
        }
      }
    }
  }

  section.seminar {
    padding: 95px 0 115px;
    @include bg-border;
    h2 {
      @include h2;
    }
    .seminar-inner {
      margin: 0 auto;
      max-width: 1200px;
    }
    .seminar-content {
      @include slide-content;
      li {
        .tag {
          span {
            margin: 0 5px 5px 0;
            padding: 3px 10px;
            line-height: 1;
            font-size: 16px;
            display: inline-block;
            font-weight: bold;
          }
          @media all and (-ms-high-contrast:none) {
            span {
              padding: 5px 10px 0;
            }
          }
          span.online {
            border: 1px $color-blue solid;
            color: $color-blue;
          }
          span.before {
            color: $color-gray1;
            background: #ebebeb;
          }
          span.accepting {
            color: #fff;
            background: $color-blue;
          }
          span.closed {
            border: 1px $color-gray3 solid;
            color: $color-gray;
          }
        }
        .tag-top {
          padding: 10px;
          width: 100%;
          box-sizing: border-box;
          span {
            margin-bottom: 0;
          }
        }
        .slide-txt {
          font-weight: bold;
          h3 {
            margin: 10px 0 30px;
            text-decoration: underline;
          }
          span.date {
            margin-top: auto;
            color: $color-gray;
            font-family: $font-en;
            font-size: 14px;
          }
        }
        &.closed {
          h3 {
            color: $color-gray;
          }
          .slide-txt  {
            span.online {
              border: 1px $color-gray3 solid;
              color: $color-gray;
            }
          }
        }
      }
    }

    .seminar-btn {
      width: 350px;
      height: 70px;
      margin: 60px auto 0 auto;
      
      a {
        line-height: 70px;
        font-size: 22px;
        padding-right: 0;
        font-size: 22px;
        @include btn-contact1;
        text-align:center;

        @include media(sp) {
          padding-right: 29.3vw;
          font-size: 3.73vw;
        }

        &::before {
          content: none;
        }
      }
    }
    // SP seminar
    @include media(sp) {
      padding: 17.33vw 0 10.93vw;
      h2 {
        @include h2;
      }
      .seminar-inner {
        margin: 0 auto;
        width: 86.66vw;
        max-width: auto;
      }
      .seminar-content {
        @include slide-content;
        li {
          .slide-li a {
            flex-wrap: wrap !important;
          }
          .tag {
            span {
              margin-right: 1.73vw;
              padding: 0 1.33vw;
              height: 4.8vw;
              line-height: 4.8vw;
              font-size: 2.66vw;
              font-weight: bold;
              text-align: center;
            }
          }
          .slide-ph {
            width: 100% !important;
            height: auto !important;
            img {
              height: 100%;
            }
          }
          .tag-top {
            padding: 1.86vw 2.66vw;
          }
          .tag-btm {
            width: 100%;
          }
          .slide-txt {
            padding: 1.86vw 4vw 3.06vw !important;
            width: 100% !important;
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            h3 {
              margin: 0 !important;
              flex-grow: 1;
              font-size: 3.46vw;
            }
            span.date {
              margin-top: 2.66vw;
              width: 100%;
              font-size: 2.66vw;
              display: block;
            }
          }
          &.closed {
            h3 {
              color: $color-gray;
            }
          }
        }
      }

      .seminar-btn {
        width: 100%;
        height: 12.5vw;
        margin: 6vw auto 0 auto;
        a {
          line-height: 12.5vw;
          padding-right: 0;
        }
      }
    }
  }
}